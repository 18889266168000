<template>
  <div id="general-config-wrapper">
    <b-loading :active="loading" :is-full-page="false" class="small" />

    <h2 class="mb-3">
      {{ $t('editing-mode') }}
    </h2>
    <p class="explanation">
      {{ $t('editing-mode-explanation') }}
    </p>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-radio v-model="editingMode" native-value="CLASSIC">
          {{ $t('classic') }}
        </b-radio>
      </div>
      <div class="column">
        <b-message type="is-info" has-icon size="is-small">
          {{ $t('classic-mode-explanation') }}
        </b-message>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-radio v-model="editingMode" native-value="RESTRICTED">
          {{ $t('restricted') }}
        </b-radio>
      </div>
      <div class="column">
        <b-message type="is-info" has-icon size="is-small">
          {{ $t('restricted-mode-explanation') }}
        </b-message>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-radio v-model="editingMode" native-value="READ-ONLY">
          {{ $t('read-only') }}
        </b-radio>
      </div>
      <div class="column">
        <b-message type="is-info" has-icon size="is-small">
          {{ $t('read-only-mode-explanation') }}
        </b-message>
      </div>
    </div>

    <h2 class="mb-3">
      {{ $t('blind-mode') }}
    </h2>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-checkbox v-model="blindMode">
          {{ $t('blind-mode') }}
        </b-checkbox>
      </div>
      <div class="column">
        <b-message type="is-info" has-icon size="is-small">
          {{ $t('blind-mode-explanation') }}
        </b-message>
      </div>
    </div>

    <h2 class="mb-3">
      {{ $t('users') }}
    </h2>

    <div class="columns">
      <div class="column is-one-quarter">
        {{ $t('max-users') }}
      </div>
      <div class="column is-one-third">
        <BField>
          <IdxInput
            v-model="maxUsers"
            min="0"
            name="max-users"
            label=""
            type="number"
          />
        </BField>
      </div>
      <div class="column">
        <b-message type="is-info" has-icon size="is-small">
          {{ $t('max-users-explanation') }}
        </b-message>
      </div>
    </div>

    <h2 class="mb-3">
      {{ $t('annotation-layers') }}
    </h2>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-checkbox v-model="hideManagersLayers">
          {{ $t('hide-managers-layers') }}
        </b-checkbox>
      </div>
      <div class="column">
        <b-message type="is-info" has-icon size="is-small">
          {{ $t('hide-managers-layers-explanation') }}
        </b-message>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-checkbox v-model="hideContributorsLayers">
          {{ $t('hide-contributors-layers') }}
        </b-checkbox>
      </div>
      <div class="column">
        <b-message type="is-info" has-icon size="is-small">
          {{ $t('hide-contributors-layers-explanation') }}
        </b-message>
      </div>
    </div>

    <template v-if="ontology">
      <h2 class="mb-3">
        {{ $t('default-terms') }}
      </h2>

      <div class="columns">
        <div class="column is-one-quarter pl-3">
          <OntologyTreeMultiselect
            v-model="defaultTermIds"
            :ontology="ontology"
            :can-select-all="false"
            @onSelect="addDefaultTerm"
            @onUnselect="removeDefaultTerm"
          />
        </div>
        <div class="column">
          <b-message type="is-info" has-icon size="is-small">
            {{ $t('default-terms-explanation') }}
          </b-message>
        </div>
      </div>
    </template>

    <h2 class="mb-3">
      {{ $t('default-annotation-layers') }}
    </h2>

    <b-field grouped>
      <b-select
        v-model="layerToAdd"
        :placeholder="$t('select-layer-placeholder')"
        size="is-small"
      >
        <option
          v-for="layer in unselectedLayers"
          :key="layer.id"
          :value="layer"
        >
          {{ fullName(layer) }}
        </option>
      </b-select>

      <button
        :disabled="!layerToAdd"
        class="button is-small"
        @click="addDefaultLayer()"
      >
        {{ $t('add') }}
      </button>
    </b-field>

    <table v-if="selectedLayers.length > 0" class="table">
      <tbody>
        <tr>
          <th>{{ $t('layer') }}</th>
          <th>{{ $t('hide-by-default') }}</th>
          <th />
        </tr>
        <tr v-for="(layer, idx) in selectedLayers" :key="layer.id">
          <td>{{ fullName(layer) }}</td>
          <td class="is-centered">
            <b-checkbox
              v-model="defaultLayers[idx].hideByDefault"
              size="is-small"
              @input="saveDefaultLayer(idx)"
            />
          </td>
          <td>
            <button class="button is-small" @click="deleteDefaultLayer(idx)">
              {{ $t('button-remove') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <h2 class="mb-3">
      {{ $t('default-property') }}
    </h2>
    <DefaultProperty />

    <h2 class="mb-3">
      {{ $t('download-permission') }}
    </h2>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-checkbox v-model="imagesDownloadable">
          {{ $t('images_downloadable_by_contributor') }}
        </b-checkbox>
      </div>
      <div class="column">
        <b-message type="is-info" has-icon size="is-small">
          {{ $t('images_downloadable_by_contributor-explanation') }}
        </b-message>
      </div>
    </div>

    <h2 class="mb-3">
      Actions
    </h2>
    <ProjectActions
      :project="project"
      size="is-normal"
      @update="externalProjectUpdate"
      @delete="deleteProject()"
    />
  </div>
</template>

<script>
import {
  Project,
  ProjectDefaultLayer,
  ProjectDefaultLayerCollection,
} from 'cytomine-client';
import ProjectActions from '../ProjectActions.vue';
import DefaultProperty from './DefaultProperty.vue';
import { fullName } from '@/utils/user-utils.js';
import DomainTagInput from '@/components/utils/DomainTagInput.vue';
import OntologyTreeMultiselect from '@/components/ontology/OntologyTreeMultiselect.vue';

import noteApi from '@/services/noteApi.js';

export default {
  name: 'GeneralConfiguration',
  components: {
    ProjectActions,
    DefaultProperty,
    DomainTagInput,
    OntologyTreeMultiselect,
  },
  data() {
    return {
      editingMode: '',
      blindMode: null,
      hideManagersLayers: null,
      hideContributorsLayers: null,
      imagesDownloadable: null,
      maxUsers: 0,

      layerToAdd: null,
      defaultLayers: [],
      defaultTermIds: [],
      users: [],
      activeSelection: [],
      loading: false,
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    layers() {
      return this.$store.state.currentProject.members;
    },
    ontology() {
      return this.$store.state.currentProject.ontology;
    },

    currentEditingMode() {
      return this.project.isReadOnly
        ? 'READ-ONLY'
        : this.project.isRestricted
        ? 'RESTRICTED'
        : 'CLASSIC';
    },

    selectedLayers() {
      return this.defaultLayers.map((defaultLayer) => {
        const layer = this.layers.find(
          (layer) => layer.id === defaultLayer.user
        );
        return {
          ...defaultLayer,
          ...layer,
        };
      });
    },
    unselectedLayers() {
      const selectedLayersIds = this.defaultLayers.map((layer) => layer.user);
      return this.layers.filter(
        (layer) => !selectedLayersIds.includes(layer.id)
      );
    },
  },
  watch: {
    editingMode(mode) {
      if (mode === this.currentEditingMode) {
        return;
      }
      this.updateProject({
        isReadOnly: mode === 'READ-ONLY',
        isRestricted: mode === 'RESTRICTED',
      });
    },

    blindMode() {
      if (this.blindMode === this.project.blindMode) {
        return;
      }
      this.updateProject({ blindMode: this.blindMode });
    },

    hideManagersLayers() {
      if (this.hideManagersLayers === this.project.hideAdminsLayers) {
        return;
      }
      this.updateProject({ hideAdminsLayers: this.hideManagersLayers });
    },

    hideContributorsLayers() {
      if (this.hideContributorsLayers === this.project.hideUsersLayers) {
        return;
      }
      this.updateProject({ hideUsersLayers: this.hideContributorsLayers });
    },

    imagesDownloadable() {
      if (this.imagesDownloadable === this.project.areImagesDownloadable) {
        return;
      }
      this.updateProject({ areImagesDownloadable: this.imagesDownloadable });
    },

    maxUsers() {
      if (this.maxUsers === this.project.maxUsers || this.maxUsers < 0) {
        return;
      }
      this.updateProject({ maxUsers: this.maxUsers });
    },
  },
  async created() {
    this.initData();
    this.fetchDefaultLayers();
    this.fetchDefaultTerms();
  },
  methods: {
    fullName(layer) {
      return fullName(layer);
    },

    async initData() {
      this.editingMode = this.currentEditingMode;
      this.blindMode = this.project.blindMode;
      this.hideManagersLayers = this.project.hideAdminsLayers;
      this.hideContributorsLayers = this.project.hideUsersLayers;
      this.imagesDownloadable = this.project.areImagesDownloadable;
      this.maxUsers = this.project.maxUsers;
      const userData = await noteApi.get('/api/user.json');
      this.users = userData.collection.map((user) => {
        user.fullName = fullName(user);
        return user;
      });
    },

    async fetchDefaultLayers() {
      this.defaultLayers = (
        await ProjectDefaultLayerCollection.fetchAll({
          filterKey: 'project',
          filterValue: this.project.id,
        })
      ).array;
    },

    async fetchDefaultTerms() {
      this.defaultTermIds =
        (await noteApi.get(`/napi/project/${this.project.id}/term/default`)) ||
        [];
    },

    async updateProject(newProps) {
      const updatedProject = this.project.clone();
      updatedProject.populate(newProps);
      try {
        await updatedProject.save();
        this.$store.commit('currentProject/setProject', updatedProject);
      } catch (error) {
        console.log(error);
        this.initData(); // reset data
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-general-config-update'),
        });
      }
    },

    async addDefaultTerm(termId) {
      try {
        const success = await noteApi.post(
          `/napi/project/${this.project.id}/term/${termId}/default`
        );
        if (!success) {
          throw new Error('Error adding default term');
        }
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-default-term'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-default-term'),
        });
      }
    },

    async removeDefaultTerm(termId) {
      try {
        const success = await noteApi.delete(
          `/napi/project/${this.project.id}/term/${termId}/default`
        );
        if (!success) {
          throw new Error('Error removing default term');
        }
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-default-term'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-default-term'),
        });
      }
    },

    async addDefaultLayer() {
      if (!this.layerToAdd) {
        return;
      }

      try {
        const defaultLayer = new ProjectDefaultLayer({
          project: this.project.id,
          user: this.layerToAdd.id,
        });
        await defaultLayer.save();
        this.defaultLayers.push(defaultLayer);
        this.layerToAdd = null;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-default-layer-add'),
        });
      }
    },

    async saveDefaultLayer(idx) {
      try {
        await this.defaultLayers[idx].save();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-default-layer-update'),
        });
      }
    },

    async deleteDefaultLayer(idx) {
      try {
        await this.defaultLayers[idx].delete();
        this.defaultLayers.splice(idx, 1);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-default-layer-delete'),
        });
      }
    },

    externalProjectUpdate(updatedProject) {
      this.$store.dispatch('currentProject/updateProject', updatedProject);
    },

    async deleteProject() {
      try {
        await Project.delete(this.project.id);
        this.$notify({
          type: 'success',
          text: this.$t('project-deletion-notif-success', {
            projectName: this.project.name,
          }),
        });
        this.$router.push('/projects');
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-deletion-notif-error', {
            projectName: this.project.name,
          }),
        });
      }
    },

    getUserByEmail(email) {
      return this.users.find((a) => a.email === email);
    },
  },
};
</script>

<style>
#general-config-wrapper .taginput .taginput-container .autocomplete input {
  width: 100%;
  min-width: unset;
}
</style>
<style scoped>
h2 {
  color: #444;
  border-bottom: 1px solid #ddd;
  margin-top: 1.5em;
}

h2:first-child {
  margin-top: 0;
}

.columns {
  align-items: center;
}

.column {
  padding: 0.8em;
}

.column:first-child {
  padding-left: 3.5em;
}

.explanation {
  margin-bottom: 1.4em;
}

.is-centered {
  text-align: center;
}

th,
td {
  padding: 0.5em 2em !important;
}

.ontology-tree-container {
  max-height: 30vh;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

>>> .sl-vue-tree-node-item {
  font-size: 0.9em;
}

>>> .message-body {
  padding: 1em !important;
}

>>> select,
>>> input[type='text'] {
  width: 26em;
}
</style>
