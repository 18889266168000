<template>
  <div class="p-5">
    <h2 class="text-center mb-3">
      {{ $t('algorithm-launch') }}
    </h2>
    <div v-if="isAdmin && availableAssays.length" class="mb-3 text-center" st>
      <BSelect id="select-algorithm" v-model="algorithmSelected" expanded>
        <option
          v-for="option in availableAssays"
          :key="option.id"
          :value="option.id"
        >
          {{ option.name }} (v{{ option.fileVersion }})
        </option>
      </BSelect>
      <IdxBtn
        :color="algorithmSelected ? 'primary' : ''"
        :disabled="!algorithmSelected"
        data-label="LaunchAlgorithmBtn"
        class="mt-3"
        @click="launchJobModal"
      >
        {{ $t('launch') }}
      </IdxBtn>
    </div>
    <div v-if="!availableAssays.length">
      <div class="content has-text-grey has-text-centered mt-5">
        <p>{{ $t('no-assay-available') }}</p>
      </div>
    </div>

    <LaunchV2JobModal
      :active.sync="openV2JobModal"
      :assay-params="v2AssayParams"
      :algorithm-selected="algorithmSelected"
      :selected-img-ids="[image.id]"
      :id-project="project.id"
      :revision.sync="revision"
    />
    <LaunchJobModal
      :active.sync="openJobModal"
      :assay-params="assayParams"
      :algorithm-selected="algorithmSelected"
      :selected-img-ids="[image.id]"
      :id-project="project.id"
      :revision.sync="revision"
    />
  </div>
</template>

<script>
import { LaunchV2JobModal, LaunchJobModal } from '../../image/index.js';
import noteApi from '@/services/noteApi.js';

/**
 * @typedef {{
 * key: string
 * value: string
 * units?: string
 * class?: string
 * min?: number
 * max?: number
 * }} Property
 */

export default {
  name: 'AssayPanel',
  components: {
    LaunchV2JobModal,
    LaunchJobModal,
  },
  props: {
    index: { type: String, default: '' },
  },
  data() {
    return {
      loading: true,
      error: false,

      //asays
      availableAssays: [],
      algorithmSelected: null,
      openV2JobModal: false,
      v2AssayParams: [],
      openJobModal: false,
      assayParams: {},
      revision: 0,
    };
  },
  computed: {
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    /** @returns {object} */
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    /** @returns {object} */
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    /** @returns {object} */
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {object} */
    image() {
      return this.viewerWrapper.images[this.index].imageInstance;
    },
    /** @returns {boolean} */
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    isAdmin() {
      return this.currentUser.admin;
    },
  },
  async created() {
    try {
      // Get Assays
      const availableAssaysResult = await noteApi.get(
        `/napi/project/${this.project.id}/algorithm`
      );
      this.availableAssays = availableAssaysResult.map((assay) => {
        return {
          id: assay.assayId,
          name: assay.name,
          fileVersion: assay.fileVersion,
        };
      });
    } catch (error) {
      console.log(error);
      this.error = true;
    }
    this.loading = false;
  },
  methods: {
    async launchJob() {
      const { algorithmSelected } = this;
      if (!algorithmSelected) return;

      try {
        this.$notify({
          type: 'success',
          text: this.$t('algorithm-run-start'),
        });
        await noteApi.post(`napi/algorithm/${algorithmSelected}/run`, {
          data: {
            projectId: this.project.id,
            selectedImgIds: this.image.id,
          },
        });
      } catch (err) {
        console.log(err);
        this.$notify({
          type: 'error',
          text: this.$t('algorithm-run-error'),
        });
      }
      this.algorithmSelected = '';
    },
    async launchJobModal() {
      try {
        var assayJson = await noteApi.get(
          `napi/algorithm/${this.algorithmSelected}/download`
        );
        const params = assayJson.params || assayJson.param_values;
        if (Object.keys(params).length == 0) {
          this.launchJob();
        } else {
          this.selectedImgIds = [this.image.id];

          if (assayJson.param_values) {
            // v1 assay
            this.assayParams = params;
            this.openJobModal = true;
          } else {
            // v2 assay
            this.v2AssayParams = params;
            this.openV2JobModal = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
td {
  word-wrap: break-word;
}
</style>
