<template>
  <div class="box relative">
    <BLoading :is-full-page="false" class="small" :active="loading" />
    <h2 class="mb-3">{{ title }} ({{ nbAnnotations }})
</h2>
    <template v-if="error">
      <BMessage type="is-danger" has-icon icon-size="is-small">
        {{ $t('annotations-failed-fetch') }}
      </BMessage>
    </template>
    <template v-else-if="!annotations.length">
      <em>{{ $t('no-annotation') }}</em>
    </template>
    <template v-else>
      <div class="flex flex-wrap gap-8 mb-2">
        <AnnotationPreview
          v-for="annot in annotations"
          :key="title + annot.id"
          :annot="annot"
          :size="size"
          :color="color"
          :terms="allTerms"
          :users="allUsers"
          :images="allImages"
          @addTerm="$emit('addTerm', $event)"
          @update="$emit('update', annot.id)"
        />
      </div>

      <BPagination
        :total="nbAnnotations"
        :current.sync="currentPage"
        :per-page="nbPerPage"
        size="is-small"
      />
    </template>
  </div>
</template>

<script>
import { AnnotationCollection } from 'cytomine-client';
import AnnotationPreview from './AnnotationPreview.vue';

export default {
  name: 'ListAnnotationsByTerm',
  components: { AnnotationPreview },
  props: {
    nbPerPage: { type: Number, default: undefined },
    size: { type: Number, default: undefined },
    color: { type: String, default: undefined },

    term: { type: Object, required: true },
    multipleTerms: { type: Boolean, default: false },
    noTerm: { type: Boolean, default: false },
    imagesIds: { type: Array, default: () => [] },
    usersIds: { type: Array, default: () => [] },
    tagsIds: { type: Array, default: () => [] },
    noTag: { type: Boolean, default: false },
    reviewed: { type: Boolean, default: false },
    reviewUsersIds: { type: Array, default: () => [] },
    afterThan: { type: Number, default: undefined },
    beforeThan: { type: Number, default: undefined },

    allTerms: { type: Array, default: () => [] },
    allUsers: { type: Array, default: () => [] },
    allImages: { type: Array, default: () => [] },

    revision: { type: Number, default: 0 },
  },
  data() {
    return {
      loading: false,
      error: false,
      annotations: [],
      nbAnnotations: 0,
      openedAnnot: 0,
    };
  },
  computed: {
    /** @returns {object} */
    collection() {
      this.revision; // to ensure that collection is reloaded if revision changes
      return new AnnotationCollection({
        images: this.imagesIds,
        term: this.multipleTerms || this.noTerm ? null : this.term.id,
        noTerm: this.noTerm,
        users: this.usersIds,
        reviewed: this.reviewed,
        reviewUsers: this.reviewUsersIds,
        //tags: this.tagsIds,
        //noTag: this.noTag,
        multipleTerm: this.multipleTerms,
        showTerm: true,
        showGIS: true,
        afterThan: this.afterThan,
        beforeThan: this.beforeThan,
        max: this.nbPerPage,
      });
    },
    /** @returns {string | import('vue-i18n').TranslateResult} */
    title() {
      if (this.multipleTerms) {
        return this.$t('multiple-terms');
      }
      if (this.noTerm) {
        return this.$t('no-term');
      }
      return this.term.name;
    },
    /** @returns {{ id: number }} */
    currentProject() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {object} */
    projectModule() {
      return this.$store.getters['currentProject/currentProjectModule'];
    },
    /** @type {import('vue').ComputedOptions<number>} */
    currentPage: {
      get() {
        return (
          (this.$store.state.projects[this.currentProject.id].listAnnotations
            .currentPages[this.term.id] || 1)
        );
      },
      set(page) {
        this.$store.commit(
          this.projectModule + 'listAnnotations/setCurrentPage',
          {
            term: this.term.id,
            page,
          }
        );
      },
    },
  },
  watch: {
    currentPage() {
      this.fetchPage();
    },
    collection() {
      this.fetchPage();
    },
  },
  created() {
    this.fetchPage();
  },
  methods: {
    async fetchPage() {
      if (
        !this.imagesIds.length ||
        (!this.reviewed && !this.usersIds.length) ||
        (this.reviewed && !this.reviewUsersIds.length)
      ) {
        this.annotations = [];
        this.nbAnnotations = 0;
        return;
      }

      this.loading = true;

      try {
        const data = await this.collection.fetchPage(this.currentPage - 1);
        this.annotations = data.array;
        this.nbAnnotations = data.totalNbItems;

        // if openedAnnot no longer in collection (can happen if term was removed from annotation),
        // reset openedAnnot value (otherwise, if annot added again to collection, popover reopens)
        if (
          !this.annotations.map((annot) => annot.id).includes(this.openedAnnot)
        ) {
          this.openedAnnot = 0;
        }
      } catch (error) {
        if (this.currentPage > 1) {
          // error may be due to the page number (not enough annots) => retry on first page
          this.currentPage = 1;
          return;
        }

        console.log(error);
        this.nbAnnotations = 0;
        this.error = true;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
>>> ul.pagination-list {
  justify-content: flex-end;
}
</style>
