<template>
  <!-- eslint-disable -->
  <BModal
    :active="isActive"
    :destroy-on-hide="false"
    trap-focus
    aria-role="dialog"
    aria-modal
    has-modal-card
    @close="$emit('update:isActive', false)"
  >
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('slides') }} ({{ data.length }})
        </p>
        <!-- eslint-enable -->
      </header>
      <div class="card-content">
        <BTable :data="data" paginated per-page="10" pagination-size="is-small">
          <BTableColumn
            v-slot="props"
            :label="$t('name')"
            field="instanceFilename"
            sortable
            cell-class="has-text-left"
          >
            {{ props.row.instanceFilename }}
          </BTableColumn>
          <template v-for="dataColumn in dataColumns">
            <BTableColumn
              v-slot="props"
              :key="dataColumn"
              :label="dataColumn"
              :field="dataColumn"
              sortable
              cell-class="px-3"
            >
              {{ props.row[dataColumn] }}
            </BTableColumn>
          </template>

          <BTableColumn v-slot="props" centered>
            <RouterLink
              :to="`/project/${projectId}/image/${props.row.id}`"
              target="_blank"
            >
              <IdxBtn small full-width color="primary">
                {{ $t('view-slide') }}
              </IdxBtn>
            </RouterLink>
          </BTableColumn>
        </BTable>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  name: 'ListSlidesModal',
  props: {
    data: {
      type: Array,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    dataColumns: {
      type: Array,
      required: false,
    },
  },
};
</script>
