<template>
  <div v-if="!fetchingChannels" class="channel-manipulation">
    <div v-if="channelGroups.length > 0" class="text-center my-3 px-5">
      <BSelect
        :key="activeChannelGroup.id"
        placeholder="Default"
        expanded
        :value="activeChannelGroup.id"
        @input="selectChannelGroup"
      >
        <option v-if="!activeChannelGroup.id">
          Default
        </option>
        <option
          v-for="channelGroup in channelGroups"
          :key="channelGroup.id"
          :value="channelGroup.id"
        >
          {{ channelGroup.groupName }}
        </option>
      </BSelect>
    </div>
    <table
      class="grid table pb-4 mb-5"
      style="grid-template-columns: auto 66px;"
    >
      <thead class="display-contents">
        <tr class="display-contents">
          <th align="center">
            <span>{{ $t('channels') }}</span>
          </th>
          <!-- <th>
            <span
              v-tooltip="$t('hist-equalization')"
              class="far fa-chart-bar"
            />
          </th> -->
          <th class="pl-0">
            <span v-tooltip="$t('visibility')" class="far fa-eye p-1" />
            <BCheckbox
              v-model="isAllChannelsChecked"
              class="mr-0"
              size="is-small"
              :disabled="channels.length === 0"
              @input="toggleAllChannelsVisibility(isAllChannelsChecked)"
            />
          </th>
        </tr>
      </thead>
      <tbody class="display-contents">
        <template v-if="channels.length">
          <tr
            v-for="(channel, index) of channelsCopy"
            :key="index"
            class="display-contents"
          >
            <td>
              <div v-if="isEditing(channel.id) && channel.visible">
                <BField
                  :type="{ 'is-info': !activeChannelGroup.id }"
                  :message="
                    !activeChannelGroup.id
                      ? $t('channel-rename-disabled-default')
                      : ''
                  "
                >
                  <BInput
                    :key="channel.columnName"
                    :disabled="!activeChannelGroup.id"
                    :value="channel.columnName"
                    size="is-small"
                    required
                    lazy
                    @input="(val) => saveChannel(channel, val)"
                  />
                </BField>
                <Chrome
                  :value="channel.color"
                  @input="colorChanged($event, channel.id)"
                  @mouseup.native="storeColor"
                />
                <div class="flex justify-between ml-5 px-1">
                  <IdxBtn
                    v-for="color in colorPresets"
                    :key="color.hex"
                    plain
                    :style="{ backgroundColor: color.hex }"
                    class="w-12 h-12"
                    @click="choseColor(channel.id, ...color.rgba)"
                  >
                    <span class="visually-hidden">{{ color.name }}</span>
                  </IdxBtn>
                </div>
                <div class="mt-4">
                  <CytomineSlider
                    :value="[channel.min, toSliderGamma(channel), channel.max]"
                    :min="channelGroupDefaults.min"
                    :max="channelGroupDefaults.max"
                    tooltip="hover"
                    :enable-cross="false"
                    :custom-tooltip="['Min', 'Gamma', 'Max']"
                    @input="
                      (vals, index) => setMinMaxGamma(channel, vals, index)
                    "
                    @drag="
                      (vals, index) => minMaxGammaDrag(channel, vals, index)
                    "
                  />
                  <table class="">
                    <tr class="text-right">
                      <td class="w-256 py-1 px-1">
                        <div class="text-center">
                          {{ $t('min') }}
                        </div>
                        <BInput
                          type="number"
                          min="0"
                          max="255"
                          :value="channel.min"
                          size="is-small"
                          style="min-width:60px;"
                          @input="(val) => setChannelMin(channel.id, val)"
                        />
                      </td>
                      <td class="w-256 py-1 px-1">
                        <div class="text-center">
                          {{ $t('gamma') }}
                        </div>
                        <BInput
                          style="min-width:60px;"
                          size="is-small"
                          :value="channel.gamma"
                          type="number"
                          label="test"
                          min="0"
                          :max="maxGamma"
                          step="0.01"
                          @input="(val) => setChannelGamma(channel.id, val)"
                        />
                      </td>
                      <td class="w-256 py-1 px-1">
                        <div class="text-center">
                          {{ $t('max') }}
                        </div>
                        <BInput
                          type="number"
                          min="0"
                          max="255"
                          :value="channel.max"
                          size="is-small"
                          style="min-width:60px;"
                          @input="(val) => setChannelMax(channel.id, val)"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div v-else>
                <div :key="channel.columnName" align="center" class="mb-2">
                  {{ channel.columnName }} ({{
                    channel.columnIndex || channel.id
                  }})
                </div>
                <table id="info-table">
                  <tr>
                    <td>
                      <span style="vertical-align:top;">Color:</span>
                      <span class="ml-2">
                        <ColorCircle :size="14" :color="channel.color" />
                      </span>
                    </td>
                    <td>
                      <span style="vertical-align:top;">Min:</span>
                      <span class="ml-2" style="vertical-align:top;">
                        {{ channel.min }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Gamma:
                      <span class="ml-2">
                        {{ channel.gamma }}
                      </span>
                    </td>
                    <td>
                      Max:
                      <span class="ml-2">
                        {{ channel.max }}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <!-- <td align="center">
              <BCheckbox
                class="m-0"
                size="is-small"
                :value="channel.histEqualization"
                expanded
                @input="
                  toggleHistEqualization(channel.id, channel.histEqualization)
                "
              />
            </td> -->
            <td class="flex align-start pl-0">
              <IdxBtn
                :disabled="!channel.visible"
                :color="isEditing(channel.id) ? 'blue' : ''"
                style="width:24px; height:24px;"
                small
                @click="toggleEdit(channel)"
              >
                <i class="fa fa-pencil-alt" style="font-size:10px;" />
              </IdxBtn>
              <IdxBtn
                style="width:24px; height:24px;"
                :color="channel.visible ? 'blue' : ''"
                class="ml-2"
                small
                @click="toggleVisibility(channel, channel.visible)"
              >
                <i class="far fa-eye" style="font-size:10px;" />
              </IdxBtn>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="mt-5">
      <IdxBtn small class="mr-2" @click="isVisibleChannelRenameModal = true">
        {{ $t('bulk-rename-channels') }}
      </IdxBtn>
      <div style="float:right;">
        <IdxBtn
          v-if="
            activeChannelGroup.id &&
              !project.isClosed &&
              (activeChannelGroup.createdBy === currentUser.id ||
                currentUser.admin)
          "
          small
          @click="saveToProject"
        >
          {{ $t('save') }}
        </IdxBtn>

        <IdxBtn
          v-if="
            activeChannelGroup.id &&
              !project.isClosed &&
              (activeChannelGroup.createdBy === currentUser.id ||
                currentUser.admin)
          "
          small
          color="red"
          class="ml-1"
          @click="confirmDeletion"
        >
          {{ $t('delete') }}
        </IdxBtn>
      </div>
    </div>

    <div class="text-right mt-1 pb-5">
      <IdxBtn small class="mr-2" style="float:left;" @click="resetToDefault">
        {{ $t('button-reset-to-default') }}
      </IdxBtn>
      <IdxBtn small class="ml-1" @click="() => setShowNamingModal(true)">
        {{ $t('save-as-new-channel-profile') }}
      </IdxBtn>
    </div>
    <!-- <CytomineModal
      v-if="showNamingModal"
      title="Save As..."
      @close="() => setShowNamingModal(false)"
    >
      <VForm v-slot="form" class="content" @submit.prevent="saveToProjectAsNew">
        <IdxInput 
          v-model="newChannelGroupName" 
          name="groupName"
          :label="$t('name')"
          required 
        />
        <div>
          <IdxBtn @click="() => setShowNamingModal(false)">
            {{ $t('cancel') }}
          </IdxBtn>
          <IdxBtn type="submit" :disabled="!form.valid">
            {{ $t('save') }}
          </IdxBtn>
        </div>
      </VForm>
      
    </CytomineModal>

    <RenameModal 
      :active.sync="showNamingModal"
      :current-name="newChannelGroupName"
      :title="$t('save-as')"
      @rename="saveToProjectAsNew"
    /> -->
    <ChannelRenameModal
      :active.sync="isVisibleChannelRenameModal"
      @updateOldColorMaps="updateColorMaps"
      @updateOldChannels="updateChannels"
      @deletedChannel="removeChannel"
    />
  </div>
</template>

<script>
import { Chrome } from 'vue-color';
import noteApi from '../../../services/noteApi.js';

import ColorCircle from '../../utils/ColorCircle.vue';
import CytomineSlider from '../../form/CytomineSlider.vue';
import IdxInput from '../../global/IdxInput.vue';
import CytomineModal from '../../utils/CytomineModal.vue';
import { MAX_GAMMA, sliderToRealGamma } from '../../../utils/image-utils.js';
import ChannelRenameModal from '../../image/ChannelRenameModal.vue';
import IdxBtn from '@/components/global/IdxBtn.vue';
import RenameModal from '@/components/utils/RenameModal';
import UploadedFileDetailsVue from '@/components/storage/UploadedFileDetails.vue';

/**
 * @typedef {{
 * name: string,
 * hex: string,
 * rgba: [number, number, number, number],
 * }} ColorPreset
 */

export default {
  name: 'ChannelManipulation',
  components: {
    Chrome,
    ColorCircle,
    CytomineSlider,
    ChannelRenameModal,
    CytomineModal,
    IdxInput,
    IdxBtn,
    RenameModal,
  },
  props: {
    index: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      color: null,
      /** @type {Array<ColorPreset>} */
      colorPresets: [
        {
          name: 'Orange',
          hex: '#ffa500',
          rgba: [255, 165, 0, 1],
        },
        {
          name: 'Yellow',
          hex: '#ffff00',
          rgba: [255, 255, 0, 1],
        },
        {
          name: 'Green',
          hex: '#00ff00',
          rgba: [0, 255, 0, 1],
        },
        {
          name: 'Teal',
          hex: '#00ffff',
          rgba: [0, 255, 255, 1],
        },
        {
          name: 'Blue',
          hex: '#0000ff',
          rgba: [0, 0, 255, 1],
        },
        {
          name: 'Magenta',
          hex: '#ff00ff',
          rgba: [255, 0, 255, 1],
        },
        {
          name: 'Red',
          hex: '#ff0000',
          rgba: [255, 0, 0, 1],
        },
      ],
      channelsBeingEdited: [],
      newChannelGroupName: '',
      showNamingModal: false,
      visibleChannels: {},
      maxGamma: MAX_GAMMA,

      fetchingChannels: false,
      colorGroups: [],
      colorGroupNames: new Map(),
      colorMaps: [],

      isVisibleChannelRenameModal: false,
    };
  },
  computed: {
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    currentViewerPath() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    /** @returns {string} */
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    /** @returns {object} */
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    activeChannelGroup() {
      return this.imageWrapper.channels.activeGroup;
    },
    /**
     * @returns {Array<{ 
     * color: {r: number, g: number, b: number},
     * histEqualization: boolean,
     * id: number,
     * name: string,
     * value: [number, number, number]
     * visible: boolean
      }>} */
    channels() {
      return this.imageWrapper.channels.activeGroup.colorMaps;
    },
    channelGroups() {
      return this.imageWrapper.channels.channelGroups;
    },
    /** @returns {CytoImageInstance} */
    image() {
      return this.imageWrapper.imageInstance;
    },
    channelGroupDefaults() {
      const colorMap = this.imageWrapper.channels.defaultGroup.colorMaps[0];
      if (colorMap) {
        return {
          min: colorMap.min,
          max: colorMap.max,
        };
      } else {
        return {
          min: 0,
          max: 255,
        };
      }
    },
    /** @returns {boolean} */
    successfulCall() {
      return this.imageWrapper.channels.successfulCall;
    },
    isAllChannelsChecked: {
      /** @returns {boolean} */
      get() {
        return this.channelsCopy.every((channel) => channel.visible);
      },
      set() {
        this.channelsCopy.every((channel) => channel.visible);
      },
    },
    channelsCopy() {
      return [...this.channels.map((a) => ({ ...a }))];
    },
  },
  watch: {
    activeChannelGroup: {
      handler() {
        this.fetchColorGroups();
      },
      immediate: true,
    },
  },
  async created() {
    this.fetchingChannels = true;
    this.fetchColorGroups();
    this.toggleAllChannelsVisibility(false);
  },
  methods: {
    async fetchColorGroups() {
      try {
        this.colorGroups = await noteApi.get(
          `/napi/project/${this.project.id}/colormaps`
        );
        this.sortColorGroups(this.colorGroups);
        this.fetchingChannels = false;
      } catch (error) {
        console.log(error);
      }
    },
    sortColorGroups(groups) {
      const map = [];
      for (const group of groups) {
        map.push(group.colorMaps);
        const id = group.id;
        this.colorGroupNames.set(id, group.groupName);
      }
      // Store groupID and matching groupName
      for (const color of map) {
        this.colorMaps = this.colorMaps.concat(color);
      }
    },
    setIsLoading(value) {
      this.$store.commit(this.currentViewerPath + 'setIsLoading', value);
    },
    async resetToDefault() {
      this.$store.commit(this.imageModule + 'setActiveGroup', {
        ...this.imageWrapper.channels.defaultGroup,
      });
    },
    selectChannelGroup(groupId) {
      const selectedGroup = this.channelGroups.find((a) => a.id === groupId);
      if (selectedGroup) {
        this.$store.commit(this.imageModule + 'setActiveGroup', selectedGroup);
      }
    },
    saveToProject() {
      this.setIsLoading(true);
      this.$store
        .dispatch(this.imageModule + 'saveToProject', this.project.id)
        .then(() => {
          this.setIsLoading(false);
          const type = this.successfulCall ? 'success' : 'error';
          const text = this.successfulCall
            ? this.$t('notif-success-set-for-project')
            : this.$t('notif-error-set-for-project');
          this.$notify({
            type: type,
            text: text,
          });
        });
    },
    confirmDeletion() {
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-channel-group'),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.deleteChannelGroup(),
      });
    },

    async deleteChannelGroup() {
      try {
        this.setIsLoading(true);
        const index = this.channelGroups.findIndex(
          (a) => a.id === this.activeChannelGroup.id
        );
        const success = await this.$store.dispatch(
          this.imageModule + 'deleteChannelGroup',
          { projectId: this.project.id }
        );
        if (success) {
          if (this.channelGroups.length > 0) {
            const activeGroup =
              index === 0
                ? this.channelGroups[0]
                : this.channelGroups[index - 1];
            this.$store.commit(
              this.imageModule + 'setActiveGroup',
              activeGroup
            );
          } else {
            this.resetToDefault();
          }
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-channel-group-deletion'),
        });
      } finally {
        this.setIsLoading(false);
      }
    },
    toSliderGamma(channel) {
      const adaptedMax = channel.max - channel.min;
      return Math.round((channel.gamma / MAX_GAMMA) * adaptedMax + channel.min);
    },
    setShowNamingModal(value) {
      this.$eventBus.$emit('showNaming', value);
    },
    // sets slider values when handle is dropped
    setMinMaxGamma(channel, values, sliderIndex) {
      if (sliderIndex === 1) {
        const realGamma = sliderToRealGamma(values[1], channel);
        if (realGamma >= 0.02 && realGamma <= MAX_GAMMA - 0.02) {
          this.setChannelGamma(channel.id, realGamma);
        }
      } else {
        this.setChannelMin(channel.id, values[0]);
        this.setChannelMax(channel.id, values[2]);
      }
    },
    // updates channel values as slider is dragged
    minMaxGammaDrag(channel, values, sliderIndex) {
      channel.min = values[0];
      channel.max = values[2];
      if (sliderIndex === 1) {
        channel.gamma = sliderToRealGamma(values[1], channel);
      }

      if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout);
      }

      this.refreshTimeout = setTimeout(() => {
        if (sliderIndex === 1) {
          const realGamma = sliderToRealGamma(values[1], channel);
          if (realGamma >= 0.02 && realGamma <= MAX_GAMMA - 0.02) {
            this.setChannelGamma(channel.id, realGamma);
          }
        } else {
          this.setChannelMin(channel.id, values[0]);
          this.setChannelMax(channel.id, values[2]);
        }
      }, 150);
    },
    isEditing(channelId) {
      return this.channelsBeingEdited.includes(channelId);
    },
    /**
     * @param {number} id
     * @param {number} r
     * @param {number} g
     * @param {number} b
     * @param {number} a
     */
    choseColor(id, r, g, b, a) {
      this.$store.commit(this.imageModule + 'setChannelColor', {
        id: id,
        color: {
          r: r,
          g: g,
          b: b,
          a: a,
        },
      });
    },
    setChannelMin(id, val) {
      const intVal = parseInt(val);
      if (intVal || intVal === 0) {
        this.$store.commit(this.imageModule + 'setChannelMin', {
          channelId: id,
          min: intVal,
        });
      }
    },
    setChannelMax(id, val) {
      const intVal = parseInt(val);
      if (intVal || intVal === 0) {
        this.$store.commit(this.imageModule + 'setChannelMax', {
          channelId: id,
          max: intVal,
        });
      }
    },
    setChannelGamma(id, val) {
      const floatVal = parseFloat(val);
      if (floatVal >= 0 && floatVal <= MAX_GAMMA) {
        this.$store.commit(this.imageModule + 'setChannelGamma', {
          channelId: id,
          gamma: floatVal,
        });
      }
    },
    setChannelName(channel) {
      this.currentChannel = channel;
      this.showChannelNamingModal = true;
    },
    updateColorMaps(colormaps) {
      this.colorMaps = colormaps;
    },
    updateChannels(channelsArray) {
      const [oldChannel, newChannel] = channelsArray;
      const index = this.channels.findIndex(
        (el) =>
          el.columnName === oldChannel.columnName &&
          el.colorMapGroupId === oldChannel.colorMapGroupId &&
          el.columnIndex === oldChannel.columnIndex
      );
      this.$store.commit(this.imageModule + 'setChannelName', {
        id: this.channels[index].id,
        columnName: newChannel.columnName,
        name: newChannel.name,
        updated: newChannel.updated,
        version: newChannel.version,
      });
    },
    removeChannel(channel) {
      this.colorMaps.splice(this.colorMaps.indexOf(channel), 1);
      const index = this.channels.findIndex(
        (el) =>
          el.columnName === channel.columnName &&
          el.colorMapGroupId === channel.colorMapGroupId &&
          el.columnIndex === channel.columnIndex
      );
      this.$store.commit(this.imageModule + 'deleteChannel', {
        id: this.channels[index].id,
      });
      // Need to splice from channelsCopy because channels doesn't update after delete
      this.channelsCopy.splice(this.channelsCopy[index], 1);
    },
    async saveChannel(channel, newName) {
      const channelToUpdate = this.colorMaps.find(
        (el) =>
          el.columnName === channel.columnName &&
          el.colorMapGroupId === channel.colorMapGroupId &&
          el.columnIndex === channel.columnIndex
      );
      const oldName = channel.columnName;
      try {
        const response = await noteApi.patch(
          `napi/project/${this.project.id}/colormap/${channelToUpdate.colorMapGroupId}/color/${channelToUpdate.id}`,
          {
            json: {
              colorName: newName,
            },
          }
        );
        if (response) {
          // Update colormap of all channels to use for finding channel ids in database
          this.colorMaps[
            this.colorMaps.indexOf(channelToUpdate)
          ].columnName = newName;
          this.colorMaps[
            this.colorMaps.indexOf(channelToUpdate)
          ].name = newName;

          // Update channels array by finding old channel to replace
          // Updates when color profile is switched
          const oldChannel = { ...channel };
          oldChannel.columnName = oldName;
          oldChannel.name = oldName;
          const index = this.channels.findIndex(
            (el) =>
              el.columnName === oldChannel.columnName &&
              el.colorMapGroupId === oldChannel.colorMapGroupId &&
              el.columnIndex === oldChannel.columnIndex
          );
          this.$store.commit(this.imageModule + 'setChannelName', {
            id: this.channels[index].id,
            columnName: newName,
            name: newName,
            updated: channel.updated,
            version: channel.version,
          });

          this.$notify({
            type: 'success',
            text: this.$t('channel-rename-success', {
              oldName: oldName,
              newName: newName,
            }),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('channel-rename-error', {
              channelName: oldName,
            }),
          });
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('channel-rename-error', {
            channelName: oldName,
          }),
        });
      }
    },
    toggleEdit(channel) {
      const channelId = channel.id;
      if (this.channelsBeingEdited.includes(channelId)) {
        const index = this.channelsBeingEdited.indexOf(channelId);
        if (index >= 0) {
          // this.saveChannel(channel, channel.columnName);
          this.channelsBeingEdited.splice(index, 1);
        }
      } else {
        this.channelsBeingEdited.push(channelId);
      }
    },
    toggleVisibility(channel, visibility) {
      const channelId = channel.id;
      if (!visibility) {
        this.visibleChannels[channelId] = true;
      } else {
        this.visibleChannels[channelId] = false;
      }
      const visibleValues = Object.values(this.visibleChannels);
      this.$store.commit(this.imageModule + 'setChannelVisibility', {
        id: channelId,
        visible: !visibility,
      });
      if (visibleValues.indexOf(true) == -1) {
        this.$store.commit(this.imageModule + 'setChannelVisibility', {
          id: this.channelsCopy[0].id,
          visible: true,
        });
        this.$notify({
          type: 'warn',
          text: this.$t('channel-visibility-minimum'),
        });
      }
      if (this.isEditing(channelId)) {
        this.toggleEdit(channel);
      }
    },
    toggleAllChannelsVisibility(isVisible) {
      this.setIsLoading(true);
      for (const channel of this.channelsCopy) {
        this.toggleVisibility(channel, isVisible);
      }
      this.setIsLoading(false);
    },
    toggleHistEqualization(id, histEqualization) {
      this.$store.commit(this.imageModule + 'setChannelHistEqualization', {
        id: id,
        histEqualization: !histEqualization,
      });
    },
    colorChanged(event, id) {
      this.color = {
        id: id,
        r: event.rgba.r,
        g: event.rgba.g,
        b: event.rgba.b,
        a: 1,
      };
    },
    storeColor() {
      if (this.color == null) return;
      this.choseColor(
        this.color.id,
        this.color.r,
        this.color.g,
        this.color.b,
        this.color.a
      );
    },
  },
};
</script>

<style>
.channel-manipulation .vc-chrome {
  width: auto !important;
  background-color: transparent !important;
  box-shadow: none;
}
.channel-manipulation .vc-chrome-body,
.vc-chrome-body {
  background-color: transparent !important;
}
.channel-manipulation .vc-chrome-fields-wrap,
.vc-chrome-saturation-wrap {
  display: none !important;
}
.channel-manipulation .collapse {
  background-color: transparent !important;
  box-shadow: none;
}
.channel-manipulation .vc-chrome-alpha-wrap {
  display: none !important;
}
.channel-manipulation .vc-chrome-color-wrap {
  width: 1.5rem !important;
}
.channel-manipulation .vc-checkerboard,
.vc-chrome-active-color {
  width: 1rem !important;
  height: 1rem !important;
}
.channel-manipulation .vc-chrome-sliders {
  padding-top: 3px !important;
}
.channel-manipulation .vc-chrome-body {
  padding: 0.25em !important;
}
.channel-manipulation table tr:last-child td {
  border: none;
}
table#info-table td {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  border: none;
}
.channel-manipulation .vue-slider {
  margin: 0;
}
</style>
