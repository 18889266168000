<template>
  <div class="box-plot-container">
    <ListSlidesModal
      :data="selectedSlides"
      :is-active.sync="showSlidesModal"
      :project-id="project.id"
      :data-columns="[yAxis]"
    />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import ListSlidesModal from '../utils/ListSlidesModal.vue';
import { GroupData } from '@/utils/chart-utils.js';

export default {
  name: 'BoxPlot',
  components: {
    ListSlidesModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    columnIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    showSlidesModal: false,
    selectedSlides: [],
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    xAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).xAxis[0];
    },
    yAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).yAxis[0];
    },
  },
  watch: {
    xAxis() {
      this.initChart();
    },
    yAxis() {
      this.initChart();
    },
  },
  created() {
    // configure what's displayed in the chart controls panel
    this.$store.commit('projectVisualizations/setChartConfig', {
      xAxisGrouped: true,
    });
  },
  mounted() {
    this.initChart();
  },
  methods: {
    getGroupingValues() {
      return GroupData(this.data, this.xAxis);
    },
    getSeriesData() {
      const series = {
        name: this.yAxis,
        showInLegend: false,
        data: [],
        tooltip: {
          headerFormat: '',
          pointFormatter: function() {
            return (
              'Count: <strong>' +
              this.custom.count +
              '</strong><br/>' +
              'Maximum: <strong>' +
              this.high +
              '</strong><br/>' +
              'Upper quartile: <strong>' +
              this.q3 +
              '</strong><br/>' +
              'Median: <strong>' +
              this.median +
              '</strong><br/>' +
              'Lower quartile: <strong>' +
              this.q1 +
              '</strong><br/>' +
              'Minimum: <strong>' +
              this.low +
              '</strong><br/>'
            );
          },
        },
      };
      const outliers = {
        name: 'Outliers',
        type: 'scatter',
        showInLegend: false,
        data: [],
        marker: {
          fillColor: 'white',
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[0],
        },
        tooltip: {
          pointFormat: `{point.custom.name} <br /><br />${this.yAxis}: <strong>{point.y}</strong>`,
        },
      };
      const groupValues = this.getGroupingValues();
      for (const [index, groupVal] of groupValues.entries()) {
        const groupData = this.data.filter((a) => a[this.xAxis] === groupVal);
        const groupDataValues = groupData.map((a) => a[this.yAxis]);
        if (groupDataValues.length > 0) {
          groupDataValues.sort((a, b) => a - b);

          const quartileLength = groupDataValues.length * 0.25;
          const lowerQuartileStart = Math.floor(quartileLength);
          const lowerQuartile = groupDataValues.slice(
            lowerQuartileStart,
            lowerQuartileStart + 1
          )[0];
          const upperQuartileStart =
            groupDataValues.length === 1
              ? 0
              : Math.floor(groupDataValues.length / 2 + quartileLength);
          const upperQuartile = groupDataValues.slice(
            upperQuartileStart,
            upperQuartileStart + 1
          )[0];
          const medianStart = Math.floor(groupDataValues.length / 2);
          const median = groupDataValues.slice(medianStart, medianStart + 1)[0];
          const interQuartileRange = 1.5 * (upperQuartile - lowerQuartile);
          const max = upperQuartile + interQuartileRange;
          const min = Math.max(lowerQuartile - interQuartileRange, 0);
          series.data.push({
            x: index,
            low: min,
            q1: lowerQuartile,
            median: median,
            q3: upperQuartile,
            high: max,
            custom: {
              count: groupDataValues.length,
            },
          });

          // Find outliers
          for (const data of groupData) {
            const value = data[this.yAxis];
            if (value < min || value > max) {
              outliers.data.push({
                x: index,
                y: value,
                custom: { name: data.instanceFilename, id: data.id },
              });
            }
          }
        }
      }
      return [series, outliers];
    },
    initChart() {
      const seriesData = this.getSeriesData();
      // @ts-ignore
      const chartOptions = {
        chart: {
          type: 'boxplot',
          zoomType: 'xy',
        },
        title: {
          text: `${this.yAxis} grouped by ${this.xAxis}`,
        },
        xAxis: {
          categories: this.getGroupingValues(),
          title: {
            text: this.xAxis,
          },
        },
        yAxis: {
          title: {
            text: this.yAxis,
          },
          labels: {
            overflow: 'justify',
          },
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {
                  if (event.point.custom.id) {
                    // user clicked on an outlier -> redirect to image viewer
                    const routeData = this.$router.resolve({
                      path: `/project/${this.project.id}/image/${event.point.custom.id}`,
                    });
                    window.open(routeData.href, '_blank');
                  } else {
                    // user clicked on a box plot -> show a modal of the data
                    const groupValues = this.getGroupingValues();
                    const groupVal = groupValues[event.point.x];
                    this.selectedSlides = this.data.filter(
                      (a) => a[this.xAxis] === groupVal
                    );
                    this.selectedSlides.sort(
                      (a, b) => a[this.yAxis] - b[this.yAxis]
                    );
                    this.showSlidesModal = true;
                  }
                },
              },
            },
          },
        },
        series: seriesData,
      };

      this.$emit('buildChart', {
        chartOptions,
      });
    },
  },
};
</script>

<style scoped></style>
