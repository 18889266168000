import { Style, Stroke, Fill, Circle, Text } from 'ol/style';
import { MultiPoint } from 'ol/geom';
import { asArray as hexToRgb } from 'ol/color';

// -----

const width = 2;

const blue = [0, 153, 255, 1];
const green = [51, 160, 79, 1];
const lightGreen = [17, 214, 76, 1];
const red = [200, 40, 40, 1];
const lightRed = [255, 56, 56, 1];
const white = [255, 255, 255, 1];

const blueStroke = new Stroke({
  color: blue,
  width: width,
});
const greenStroke = new Stroke({
  color: green,
  width: width + 1,
});
const lightGreenStroke = new Stroke({
  color: lightGreen,
  width: width,
});
const redStroke = new Stroke({
  color: red,
  width: width + 1,
});
const lightRedStroke = new Stroke({
  color: lightRed,
  width: width,
});
const whiteStroke = new Stroke({
  color: white,
  width: width + 2,
});

// -----

/**
 * @param feature
 */
export function isCluster(feature) {
  const annot = feature.get('annot');
  if (!annot) {
    return;
  }
  return annot.count != null;
}

// -----

const strokeStyleCache = {};
/**
 * @param hexColor
 * @param width
 * @param opacity
 */
function createStroke(hexColor, width, opacity = 0.5) {
  const cachedStyle = strokeStyleCache[`${hexColor},${width},${opacity}`];
  if (cachedStyle) return cachedStyle;

  let colorArray = [0, 0, 0, 0];
  let colorWithOpacity = [0, 0, 0, 0];
  colorArray = hexToRgb(hexColor);
  colorWithOpacity = colorArray.slice();
  colorWithOpacity[3] = opacity;

  const strokeStyle = new Stroke({
    color: colorWithOpacity,
    width: width,
  });

  strokeStyleCache[`${hexColor},${width},${opacity}`] = strokeStyle;

  return strokeStyle;
}

const createColorStyleCache = {};
/**
 * @param color
 * @param fillColor
 * @param strokeColor
 * @param strokeWidth
 * @param opacity
 * @param radius
 */
export function createColorStyle(
  fillColor,
  strokeColor,
  strokeWidth,
  opacity = 0.5,
  radius = 1
) {
  const cachedStyle =
    createColorStyleCache[
      `${fillColor},${strokeColor},${strokeWidth},${opacity},${radius}`
    ];
  if (cachedStyle) return cachedStyle;

  let colorArray = [0, 0, 0, 0];
  let colorWithOpacity = [0, 0, 0, 0];
  if (fillColor) {
    colorArray = hexToRgb(fillColor);
    colorWithOpacity = colorArray.slice();
    colorWithOpacity[3] = opacity;
  }

  const fill = new Fill({ color: colorWithOpacity });

  const circleStyle = new Circle({
    radius: radius,
    fill: new Fill({ color: colorArray }),
    stroke: createStroke(strokeColor, strokeWidth, 1),
  });
  circleStyle.setOpacity(opacity);
  const style = new Style({
    fill,
    stroke: createStroke(strokeColor, strokeWidth, opacity),
    image: circleStyle,
  });
  createColorStyleCache[
    `${fillColor},${strokeColor},${strokeWidth},${opacity},${radius}`
  ] = style;
  return style;
}

// -----
const lineStyleCache = {};
/**
 * @param color
 * @param opacity
 */
export function createLineStyle(color, opacity = 0.5) {
  const cachedStyle = lineStyleCache[`${color},${opacity}`];
  if (cachedStyle) return cachedStyle;

  let colorArray = [0, 0, 0, 0];
  let colorWithOpacity = [0, 0, 0, 0];
  if (color) {
    colorArray = hexToRgb(color);
    colorWithOpacity = colorArray.slice();
    colorWithOpacity[3] = opacity;
  }

  const style = new Style({
    stroke: new Stroke({
      color: colorWithOpacity,
      width: 3,
    }),
  });
  lineStyleCache[`${color},${opacity}`] = style;
  return style;
}

// -----

const textFill = new Fill({ color: '#fff' });
const textStroke = new Stroke({
  color: '#000',
  width: 3,
});

/**
 * @param text
 * @param fontSize
 * @param fill
 * @param stroke
 */
export function createTextStyle(
  text,
  fontSize = '22px',
  fill = textFill,
  stroke = textStroke
) {
  return new Style({
    text: new Text({
      text,
      font: `${fontSize} Arial, sans-serif`,
      overflow: true,
      fill,
      stroke,
    }),
  });
}
const selectStylesCache = {};
export const getSelectStyles = (pointSize) => {
  const cachedStyle = selectStylesCache[pointSize];
  if (selectStylesCache[pointSize]) return [cachedStyle];
  const style = new Style({
    stroke: blueStroke,
    image: new Circle({
      radius: pointSize,
      stroke: blueStroke,
    }),
  });
  selectStylesCache[pointSize] = style;
  return [style];
};
export const verticesStyle = new Style({
  image: new Circle({
    radius: width + 1,
    fill: new Fill({ color: blue }),
  }),
  geometry: function(feature) {
    // return the coordinates of the first ring of the polygon
    var coordinates = feature.getGeometry().getCoordinates()[0];
    return new MultiPoint(coordinates);
  },
});

const reviewedStylesCache = {};
export const getReviewedStyles = (pointSize) => {
  const cachedStyle = reviewedStylesCache[pointSize];
  if (reviewedStylesCache[pointSize]) return [cachedStyle];

  const style = new Style({
    stroke: greenStroke,
    image: new Circle({
      radius: pointSize,
      stroke: greenStroke,
    }),
  });
  reviewedStylesCache[pointSize] = style;
  return [style];
};

const reviewedSelectStylesCache = {};
export const getReviewedSelectStyles = (pointSize) => {
  const cachedStyle = reviewedSelectStylesCache[pointSize];
  if (reviewedSelectStylesCache[pointSize]) return [cachedStyle];

  const style = new Style({
    stroke: lightGreenStroke,
    image: new Circle({
      radius: pointSize,
      stroke: lightGreenStroke,
    }),
  });
  reviewedSelectStylesCache[pointSize] = style;
  return [style];
};

const rejectedStylesCache = {};
export const getRejectedStyles = (pointSize) => {
  const cachedStyle = rejectedStylesCache[pointSize];
  if (rejectedStylesCache[pointSize]) return [cachedStyle];

  const style = new Style({
    stroke: redStroke,
    image: new Circle({
      radius: pointSize,
      stroke: redStroke,
    }),
  });
  rejectedStylesCache[pointSize] = style;
  return [style];
};

const rejectedSelectStylesCache = {};
export const getRejectedSelectStyles = (pointSize) => {
  const cachedStyle = rejectedSelectStylesCache[pointSize];
  if (rejectedSelectStylesCache[pointSize]) return [cachedStyle];

  const style = new Style({
    stroke: lightRedStroke,
    image: new Circle({
      radius: pointSize,
      stroke: lightRedStroke,
    }),
  });
  rejectedSelectStylesCache[pointSize] = style;
  return [style];
};

// -----

/**
 * @param style
 * @param opacity
 */
export function changeOpacity(style, opacity) {
  const stroke = style.getStroke();
  if (stroke) {
    stroke.getColor()[3] = opacity;
  }
  const fill = style.getFill();
  if (fill) {
    fill.getColor()[3] = opacity;
  }
  const image = style.getImage();
  if (image) {
    image.setOpacity(opacity);
  }
}

// -----

/**
 * @param name
 * @param hexaCode
 */
function createDefaultColor(name, hexaCode) {
  return {
    name,
    fill: new Fill({ color: '#' + hexaCode }),
    hexaCode,
  };
}

export const defaultColors = Object.freeze([
  createDefaultColor('black', '000000'),
  createDefaultColor('white', 'ffffff'),
  createDefaultColor('red', 'ff0000'),
  createDefaultColor('orange', 'ff6600'),
  createDefaultColor('yellow', 'ffff00'),
  createDefaultColor('green', '008000'),
  createDefaultColor('blue', '0000ff'),
  createDefaultColor('purple', '800080'),
]);
