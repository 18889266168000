import { spawn, Pool, Worker } from 'threads';

const pool = Pool(() => spawn(new Worker('./getAnnotations')), {
  concurrency: 4,
  name: 'getAnnotations',
});

const getAnnotations = async (params) => {
  return await pool.queue((workerFunc) =>
    workerFunc({
      url:
        process.env.VUE_APP_CYTOMINE_CORE_HOST + '/api/annotation/search.json',
      ...params,
    })
  );
};

export { getAnnotations };
