import { render, staticRenderFns } from "./ProjectResults.vue?vue&type=template&id=068d70cf&scoped=true&"
import script from "./ProjectResults.vue?vue&type=script&lang=js&"
export * from "./ProjectResults.vue?vue&type=script&lang=js&"
import style0 from "./ProjectResults.vue?vue&type=style&index=0&id=068d70cf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "068d70cf",
  null
  
)

export default component.exports