<template>
  <div>
    <h2 class="text-center mb-3">
      {{ $t('image-results') }}
    </h2>
    <div v-if="properties.length == 0" class="text-center color-gray-4">
      <i>{{ $t('results-not-available') }}</i>
    </div>

    <div class="my-3">
      <CytomineProperties
        :object="image"
        :show-default-qc="true"
        :hidden-props="hiddenProps"
        :can-edit="canEdit && isRepresentative && isSuperAdmin"
        @filteredProps="setProperties"
      />
    </div>
  </div>
</template>

<script>
import CytomineProperties from '@/components/property/CytomineProperties.vue';

export default {
  name: 'ResultsPanel',
  components: {
    CytomineProperties,
  },
  props: {
    index: { type: String, default: '' },
  },
  data() {
    return {
      properties: [],
      hiddenProps: ['Tile Count', 'Average Focus', 'Percent In-Focus'],
    };
  },
  computed: {
    /** @returns {object} */
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    /** @returns {object} */
    image() {
      return this.viewerWrapper.images[this.index].imageInstance;
    },
    /** @returns {boolean} */
    canEdit() {
      // checks if user is admin/superadmin
      return this.$store.getters['currentProject/canEditAnnot'](this.image);
    },
    isRepresentative() {
      return this.$store.getters['currentProject/isRepresentative'];
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    isSuperAdmin() {
      return this.currentUser.adminByNow;
    },
  },
  methods: {
    setProperties(filteredProps) {
      this.properties = filteredProps;
    },
  },
};
</script>
