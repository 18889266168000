<template>
  <div class="color-manipulation p-5">
    <div
      v-if="
        annotationCounts &&
          annotationCounts.loaded < annotationCounts.total &&
          annotationCounts.loaded > 0
      "
      class="mb-4"
    >
      <i class="fas fa-exclamation-triangle color-orange" />
      {{
        $t('annotations-loaded-count', {
          loaded: annotationCounts.loaded,
          total: annotationCounts.total,
        })
      }}
    </div>

    <div>
      {{ $t('load-all-annotations') }}
      <BSwitch
        v-model="showAllAnnotations"
        :true-value="false"
        :false-value="true"
        class="ml-3"
        size="is-small"
      />
    </div>

    <div class="mt-2" style="font-size:11px;opacity:0.7;">
      {{ $t('annotation-loading-disclaimer') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClusteringPanel',
  props: {
    index: String,
  },
  data() {
    return {
      filters: null,
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    annotationCounts() {
      return this.$store.getters[this.imageModule + 'getAnnotationCounts'];
    },
    showAllAnnotations: {
      get() {
        return this.imageWrapper.view.clustering;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'toggleClustering', value);
      },
    },
  },
  async created() {},
  methods: {},
};
</script>

<style scoped>
.color-orange {
  color: #ffa000;
}
</style>
