import { isNullOrUndefined } from './string-utils.js';

// data is a dictionary of key-value pairs
export function GroupData(data, key) {
  const valuesByDimension = data.map((data) => data[key]);
  const uniqueValues = valuesByDimension.filter(
    (val, i) =>
      valuesByDimension.indexOf(val) === i &&
      !isNullOrUndefined(val) &&
      val !== 'NA'
  );
  uniqueValues.sort((a, b) => a - b);

  return uniqueValues;
}
