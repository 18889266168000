<template>
  <div
    :class="{
      selector: allowSelection,
      draggable: allowDrag,
      editable: allowEdition,
    }"
  >
    <SlVueTree
      ref="tree"
      v-model="treeNodes"
      :allow-multiselect="false"
      @select="select"
      @drop="drop"
    >
      <template #toggle="{ node }">
        <template
          v-if="!node.data.hidden && !node.isLeaf && node.children.length > 0"
        >
          <i
            :class="[
              'tree-toggle',
              'fas',
              node.isExpanded ? 'fa-angle-down' : 'fa-angle-right',
            ]"
          />
        </template>
        <div class="sl-vue-tree-gap" />
      </template>

      <template #title="{ node }">
        <div v-if="!node.data.hidden" class="tree-selector">
          <slot name="custom-title" :term="node.data">
            <i
              v-if="allowSelection"
              :class="classNames(node)"
              class="tree-checkbox"
            />
            <CytomineTerm :term="node.data" />
          </slot>
        </div>
        <div v-else />
      </template>

      <template #sidebar="{ node }">
        <slot v-if="!node.data.hidden" name="custom-sidebar" :term="node.data">
          <div v-if="allowEdition">
            <IdxBtn class="mr-2" small @click="startTermUpdate(node)">
              <span class="icon is-small">
                <i class="fas fa-edit" />
              </span>
            </IdxBtn>
            <IdxBtn small @click="confirmTermDeletion(node)">
              <span class="icon is-small">
                <i class="far fa-trash-alt" />
              </span>
            </IdxBtn>
          </div>
        </slot>
      </template>
    </SlVueTree>

    <slot v-if="noResult" name="no-result">
      <em class="has-text-grey no-result">{{ $t('no-result') }}</em>
    </slot>

    <div v-if="allowEdition || allowNew" class="my-2 text-center">
      <IdxBtn small @click="startTermCreation">
        {{ $t('add-term') }}
      </IdxBtn>
    </div>
  </div>
</template>

<script>
import SlVueTree from 'sl-vue-tree';
import { Term } from 'cytomine-client';
import CytomineTerm from './CytomineTerm.vue';
import TermModal from './TermModal.vue';
import noteApi from '@/services/noteApi.js';
import { getWildcardRegexp } from '@/utils/string-utils';

export default {
  name: 'OntologyTree',
  components: {
    SlVueTree,
    CytomineTerm,
  },
  model: {
    prop: 'selectedNodes',
    event: 'setSelectedNodes',
  },
  props: {
    ontology: { type: Object },
    additionalNodes: {
      type: Array,
      default: () => [],
    },
    startWithAdditionalNodes: {
      type: Boolean,
      default: false,
    },
    searchString: {
      type: String,
      default: '',
    },
    selectedNodes: {
      type: Array,
      default: () => [],
    },
    allowSelection: {
      type: Boolean,
      default: true,
    },
    multipleSelection: {
      type: Boolean,
      default: true,
    },
    allowDrag: {
      type: Boolean,
      default: false,
    },
    allowEdition: {
      type: Boolean,
      default: false,
    },
    allowNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      treeNodes: [],
      internalSelectedNodes: [],
      editedNode: null,
    };
  },
  computed: {
    regexp() {
      return getWildcardRegexp(this.searchString);
    },
    noResult() {
      return this.treeNodes.every((node) => node.data.hidden);
    },
  },
  watch: {
    ontology() {
      this.makeTree();
    },
    additionalNodes() {
      this.makeTree();
    },
    selectedNodes() {
      this.internalSelectedNodes = this.selectedNodes.slice();
      this.refreshNodeSelection();
    },
    regexp() {
      this.filter();
      const visibleTerms = this.treeNodes
        .filter((node) => !node.data.hidden)
        .map((node) => node.data);

      this.$emit('termsChanged', visibleTerms);
    },
  },
  created() {
    this.internalSelectedNodes = this.selectedNodes.slice();
    this.makeTree();
  },
  methods: {
    makeTree() {
      if (!this.ontology) {
        this.treeNodes = [];
        return;
      }

      const nodes = this.createSubTree(this.ontology.terms.slice());
      const additionalNodes = this.createSubTree(this.additionalNodes.slice());
      const allNodes = this.startWithAdditionalNodes
        ? additionalNodes.concat(nodes)
        : nodes.concat(additionalNodes);

      // order the terms based on what is stored in localStorage
      const termsOrder =
        localStorage.getItem('termsOrder_' + this.ontology.id)?.split(',') ||
        [];
      if (this.allowDrag && termsOrder.length > 0) {
        const orderedNodes = [];

        termsOrder.forEach((termName) => {
          const nodeIndex = allNodes.findIndex(
            (node) => node.title === termName
          );
          if (nodeIndex >= 0) {
            orderedNodes.push(allNodes[nodeIndex]);
            allNodes.splice(nodeIndex, 1);
          }
        });

        this.treeNodes = [...orderedNodes, ...allNodes];
      } else {
        this.treeNodes = allNodes;
      }

      this.filter();
    },

    createSubTree(terms) {
      return terms.map((term) => this.createNode(term));
    },

    createNode(term) {
      return {
        title: term.name,
        isLeaf: true, // all terms can be used as parent for drag and drop
        isDraggable: this.allowDrag,
        isExpanded: true,
        isSelected: this.internalSelectedNodes.includes(term.id),
        data: {
          id: term.id,
          name: term.name,
          color: term.color,
          parent: term.parent,
          ontology: this.ontology.id,
          hidden: false,
        },
        children:
          term.children && term.children.length > 0
            ? this.createSubTree(term.children)
            : [],
      };
    },

    filter() {
      this.applyToAllNodes((node) => {
        let match = this.regexp.test(node.title);
        if (node.children) {
          const matchInChildren = node.children.some(
            (child) => !child.data.hidden
          ); // OK because applyToAllNodes performs bottom-up operations
          node.isExpanded = matchInChildren;
          match = match || matchInChildren;
        }
        node.data.hidden = !match;
      });
    },

    classNames(node) {
      if (this.multipleSelection) {
        return node.isSelected
          ? ['fas', 'fa-check-square']
          : ['far', 'fa-square'];
      } else {
        return node.isSelected
          ? ['fas', 'fa-dot-circle']
          : ['far', 'fa-circle'];
      }
    },

    select(nodes, event) {
      if (!this.allowSelection) {
        return;
      }

      if (this.clickOnTreeSelector(event.target)) {
        nodes.forEach((node) => {
          if (this.multipleSelection) {
            const indexSelected = this.internalSelectedNodes.indexOf(
              node.data.id
            );
            if (indexSelected >= 0) {
              this.internalSelectedNodes.splice(indexSelected, 1);
              this.$emit('unselect', node.data.id);
            } else {
              this.internalSelectedNodes.push(node.data.id);
              this.$emit('select', node.data.id);
            }
          } else {
            this.internalSelectedNodes = [node.data.id];
            this.$emit('select', node.data.id);
          }
        });
        this.$emit('setSelectedNodes', this.internalSelectedNodes);
      }

      this.refreshNodeSelection();
    },
    refreshNodeSelection() {
      if (!this.allowSelection) {
        return;
      }

      this.applyToAllNodes((node) => {
        node.isSelected = this.internalSelectedNodes.some(
          (id) => id === node.data.id
        );
      });
    },
    clickOnTreeSelector(elem) {
      if (elem.classList.contains('tree-selector')) {
        return true;
      }
      return elem.parentElement
        ? this.clickOnTreeSelector(elem.parentElement)
        : false;
    },
    applyToAllNodes(fct, nodes = this.treeNodes) {
      nodes.forEach((node) => {
        if (node.children) {
          this.applyToAllNodes(fct, node.children);
        }
        fct(node);
      });
    },

    startTermCreation() {
      this.editedNode = null;
      this.openModal();
    },
    createTerm(term) {
      this.treeNodes.push(this.createNode(term));
      this.$emit('newTerm', term);
    },

    startTermUpdate(node) {
      this.editedNode = node;
      this.openModal();
    },
    updateTerm(term) {
      this.$refs.tree.updateNode(this.editedNode.path, { data: { ...term } });
    },

    openModal() {
      this.$buefy.modal.open({
        parent: this,
        component: TermModal,
        props: {
          term: this.editedNode ? this.editedNode.data : null,
          ontology: this.ontology,
        },
        events: {
          newTerm: this.createTerm,
          updateTerm: this.updateTerm,
        },
        hasModalCard: true,
      });
    },

    drop(nodes, position) {
      nodes.forEach(async (node) => {
        const idParent =
          position.placement === 'inside'
            ? position.node.data.id
            : position.node.data.parent;
        if (node.data.parent !== idParent) {
          try {
            await new Term(node.data).changeParent(idParent);
            this.applyToAllNodes((tmp) => {
              if (tmp.data.id === node.data.id) {
                tmp.data.parent = idParent;
              }
            });
          } catch (error) {
            console.log(error);
            this.$notify({
              type: 'error',
              text: this.$t('notif-error-ontology-tree-update'),
            });
          }
        }
      });
      this.saveOntologyOrder();
      this.$eventBus.$emit('termsUpdated');
    },

    // save the order of terms using local storage
    saveOntologyOrder() {
      const termsOrder = this.treeNodes.map((node) => node.title);
      localStorage.setItem(
        'termsOrder_' + this.ontology.id,
        termsOrder.join(',')
      );
    },

    async confirmTermDeletion(node) {
      try {
        //  Check to see if term is being used in any annotations
        const response = await noteApi.get(
          `napi/annotation/term?termId=${node.data.id}`
        );
        let confirmDeleteMsg;
        if (response.countAffected > 0) {
          confirmDeleteMsg = this.$t('confirm-deletion-term-with-annots', {
            name: node.data.name,
          });
        } else {
          confirmDeleteMsg = this.$t('confirm-deletion-term', {
            name: node.data.name,
          });
        }
        this.$buefy.dialog.confirm({
          title: this.$t('confirm-deletion'),
          message: confirmDeleteMsg,
          type: 'is-danger',
          confirmText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
          onConfirm: () => this.deleteTerm(node),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-term-deletion'),
        });
      }
    },
    async deleteTerm(node) {
      try {
        // soft deletes ontology
        await noteApi.delete(
          `/napi/ontology/${this.ontology.id}/term/${node.data.id}`
        );
        this.$refs.tree.remove([node.path]);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-term-deletion'),
        });
      }
    },
  },
};
</script>

<style>
.ontology-tree .tree-checkbox {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 1rem;
}

.draggable .sl-vue-tree-node-item {
  cursor: move;
}

.ontology-tree.selector .sl-vue-tree-node-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.ontology-tree.selector .sl-vue-tree-selected > .sl-vue-tree-node-item {
  background: rgba(0, 0, 0, 0.05);
  font-weight: 600;
}

.ontology-tree .sl-vue-tree-selected > .sl-vue-tree-node-item .tree-checkbox {
  color: #61b2e8;
}

.ontology-tree.selector .sl-vue-tree-gap {
  width: 24px;
}

.ontology-tree.selector .tree-selector {
  cursor: pointer;
  flex-grow: 1;
}

.ontology-tree .tree-selector {
  min-width: 0; /* to allow correct handling of overflow-wrap */
}

.ontology-tree .tree-selector:hover .tree-checkbox {
  color: #61b2e8;
}

.ontology-tree .no-result {
  margin-left: 20px;
  line-height: 1.5;
  font-size: 0.9rem;
}

.ontology-tree.editable .sl-vue-tree-sidebar {
  width: 100px;
  padding-left: 20px;
  flex-shrink: 0;
}

.ontology-tree.editable .sl-vue-tree-sidebar {
  display: flex;
  align-items: top;
}
</style>
