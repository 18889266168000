<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <vl-layer-vector :update-while-interacting="true">
      <vl-source-vector ref="olSourceDrawTarget" :ident="drawSourceName" />
    </vl-layer-vector>
    <div id="mouse-circle" ref="mouseCursorEl"></div>
  </div>
</template>

<script>
import Polygon, {
  fromCircle as polygonFromCircle,
  fromExtent as polygonFromExtent,
} from 'ol/geom/Polygon';
import LinearRing from 'ol/geom/LinearRing';
import {
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
} from 'ol/geom';
import Circle from 'ol/geom/Circle';
import Feature from 'ol/Feature';

import { Draw } from 'ol/interaction';
import Vector from 'ol/source/Vector';
import { Style, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import WKTReader from 'jsts/org/locationtech/jts/io/WKTReader.js';
import Parser from 'jsts/org/locationtech/jts/io/OL3Parser.js';
import 'jsts/org/locationtech/jts/monkey.js';
import { Annotation, AnnotationType } from 'cytomine-client';
import { Action, getUnmirroredWktLocation } from '@/utils/annotation-utils.js';

export default {
  name: 'RectangleInteraction',
  props: {
    map: {
      type: Object,
      required: true,
    },
    index: String,
    mousePosition: Array,
    viewerWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      jstsReader: new WKTReader(),
      jstsParser: new Parser(),
      brushCursor: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    viewerSettings() {
      return this.$store.getters['currentProject/viewerSettings'];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    // Only necessary if we want the rectangle to stay the same size regardless of zoom level
    // rectangleSizeMapUnits() {
    //   const olMapBounds = this.map.$map
    //     .getView()
    //     .calculateExtent(this.map.$map.getSize());
    //   const olMapWidth = olMapBounds[2] - olMapBounds[0];

    //   return {
    //     width: (this.customRectangleWidth / 100) * olMapWidth,
    //     height: (this.customRectangleHeight / 100) * olMapWidth,
    //   };
    // },
    rectangleSizePx() {
      const htmlMapBounds = this.map.$el.getBoundingClientRect();
      const olMapBounds = this.map.$map
        .getView()
        .calculateExtent(this.map.$map.getSize());
      const pxMapWidth = htmlMapBounds.width;
      const olMapWidth = olMapBounds[2] - olMapBounds[0];
      const pxToMapUnitsRatio = pxMapWidth / olMapWidth;

      return {
        width: this.customRectangleWidth * pxToMapUnitsRatio,
        height: this.customRectangleHeight * pxToMapUnitsRatio,
      };
    },
    termsToAssociate() {
      return this.imageWrapper.draw.termsNewAnnots;
    },
    selectedFeature() {
      return this.$store.getters[this.imageModule + 'selectedFeature'];
    },
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    activeLayers() {
      return this.layers.filter((layer) => layer.drawOn);
    },
    drawSourceName() {
      return `draw-target-${this.index}`;
    },
    activeTool() {
      return this.imageWrapper.draw.activeTool;
    },
    customRectangleWidth() {
      return parseInt(this.viewerSettings.rectangleSize.width);
    },
    customRectangleHeight() {
      return parseInt(this.viewerSettings.rectangleSize.height);
    },
    mirroring() {
      return this.imageWrapper.draw.activeMirroring;
    },
  },
  created() {
    this.map.$el.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mousemove', this.handleMouseMove);

    this.jstsParser.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon
    );
  },
  beforeDestroy() {
    this.map.$el.removeEventListener('mousedown', this.handleMouseDown);
    this.map.$el.removeEventListener('mouseup', this.handleMouseUp);
    if (this.brushCursor) {
      this.map.$map.removeInteraction(this.brushCursor);
    }
  },
  methods: {
    handleMouseMove(event) {
      if (this.$refs.mouseCursorEl) {
        const mapBounds = this.map.$el.getBoundingClientRect();
        this.$refs.mouseCursorEl.style.top =
          event.pageY - mapBounds.top - this.rectangleSizePx.height / 2 + 'px';
        this.$refs.mouseCursorEl.style.left =
          event.pageX - mapBounds.left - this.rectangleSizePx.width / 2 + 'px';
        this.$refs.mouseCursorEl.style.margin = `${-this.rectangleSizePx.width /
          2}px 0px 0px ${-this.rectangleSizePx.width / 2}`;
        this.$refs.mouseCursorEl.style.width =
          this.rectangleSizePx.width + 'px';
        this.$refs.mouseCursorEl.style.height =
          this.rectangleSizePx.height + 'px';
      }
    },
    async handleMouseDown() {
      const extent = [
        this.mousePosition[0] - this.customRectangleWidth / 2,
        this.mousePosition[1] - this.customRectangleHeight / 2,
        this.mousePosition[0] + this.customRectangleWidth / 2,
        this.mousePosition[1] + this.customRectangleHeight / 2,
      ];
      const rectangle = polygonFromExtent(extent);
      const feature = new Feature(rectangle);

      this.activeLayers.forEach(async (layer, idx) => {
        const annot = new Annotation({
          location: getUnmirroredWktLocation(
            feature,
            this.mirroring,
            this.image
          ),
          image: this.image.id,
          user: layer.id,
          term: this.termsToAssociate,
        });
        try {
          await annot.save();

          annot.userByTerm = this.termsToAssociate.map((term) => ({
            term,
            user: [this.currentUser.id],
          }));
          this.$eventBus.$emit('addAnnotations', [annot]);
          this.$store.commit(this.imageModule + 'addAction', {
            annots: [annot],
            type: Action.CREATE,
          });
        } catch (err) {
          console.log(err);
        }
      });
    },
  },
};
</script>
<style scoped>
#mouse-circle {
  position: absolute;
  border: 1px solid #000000;
  width: 1px;
  height: 1px;
  pointer-events: none !important;
  z-index: 99999;
}
</style>
