<template>
  <div class="error-bars-container">
    <ListSlidesModal
      :data="selectedSlides"
      :is-active.sync="showSlidesModal"
      :project-id="project.id"
      :data-columns="[yAxis]"
    />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.js';
import ListSlidesModal from '../utils/ListSlidesModal.vue';
import { GroupData } from '@/utils/chart-utils.js';
import { StandardDeviation } from '@/utils/math-utils.js';

HighchartsMore(Highcharts);

export default {
  name: 'ErrorBars',
  components: {
    ListSlidesModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    columnIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    selectedSlides: [],
    showSlidesModal: false,
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    xAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).xAxis[0];
    },
    yAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).yAxis[0];
    },
  },
  watch: {
    xAxis() {
      this.initChart();
    },
    yAxis() {
      this.initChart();
    },
  },
  created() {
    // configure what's displayed in the chart controls panel
    this.$store.commit('projectVisualizations/setChartConfig', {
      xAxisGrouped: true,
    });
  },
  mounted() {
    this.initChart();
  },
  methods: {
    getSeriesData() {
      const series = {
        name: this.xAxis,
        id: 'slide',
        tooltip: {
          headerFormat: '',
          pointFormat: `Count: <strong>{point.custom.count}</strong>
            <br/>${this.xAxis}: <strong>{point.x}</strong>
            <br/>${this.yAxis} (mean): <strong>{point.y}</strong>`,
        },
        showInLegend: false,
        data: [],
      };
      const errorBar = {
        name: this.yAxis + ' Error',
        type: 'errorbar',
        tooltip: {
          headerFormat: '',
          pointFormat:
            '<br/>Error High: <strong>{point.high}</strong><br>Error Low: <strong>{point.low}</strong>',
        },
        data: [],
      };

      const seriesData = [];
      const errorBarData = [];

      const xAxisValues = GroupData(this.data, this.xAxis);
      xAxisValues.forEach((xValue) => {
        const yValues = this.data
          .filter((a) => a[this.xAxis] === xValue)
          .map((a) => a[this.yAxis]);
        if (yValues.length) {
          const mean =
            Math.round(
              (yValues.reduce((a, b) => a + b, 0) / yValues.length) * 100
            ) / 100;
          const stDev = StandardDeviation(yValues);
          const standardError = stDev / Math.sqrt(yValues.length);
          const upperBound = Math.round((mean + standardError) * 100) / 100;
          const lowerBound = Math.round((mean - standardError) * 100) / 100;
          seriesData.push({
            x: xValue,
            y: mean,
            custom: { count: yValues.length },
          });
          errorBarData.push({
            x: xValue,
            low: lowerBound,
            high: upperBound,
          });
        }
      });

      series.data.push(...seriesData);
      errorBar.data.push(...errorBarData);
      return [series, errorBar];
    },
    initChart() {
      const seriesData = this.getSeriesData();

      // @ts-ignore
      const chartOptions = {
        chart: {
          type: 'column',
          zoomType: 'xy',
        },
        title: {
          text: `${this.yAxis} grouped by ${this.xAxis}`,
        },
        subtitle: {
          text: `Error bars represent standard error of the mean, calculated using the following formula: standard deviation / sqrt(n)`,
        },
        xAxis: {
          title: {
            text: this.xAxis,
          },
        },
        yAxis: {
          title: {
            text: this.yAxis,
          },
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: (event) => {
                  // user clicked on a box plot -> show a modal of the data
                  const groupValues = GroupData(this.data, this.xAxis);
                  const groupVal = groupValues[event.point.x];
                  this.selectedSlides = this.data.filter(
                    (a) => a[this.xAxis] === groupVal
                  );
                  this.selectedSlides.sort(
                    (a, b) => a[this.yAxis] - b[this.yAxis]
                  );
                  this.showSlidesModal = true;
                },
              },
            },
          },
        },
        tooltip: {
          shared: true,
        },
        series: seriesData,
      };

      this.$emit('buildChart', {
        chartOptions,
      });
    },
  },
};
</script>

<style scoped></style>
