<template>
  <div class="layers">
    <h2 class="text-center">
      {{ $t('annotation-layers') }}
    </h2>
    <BMessage
      v-if="error"
      type="is-danger"
      has-icon
      icon-size="is-small"
      size="is-small"
    >
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </BMessage>
    <template v-else>
      <table class="table">
        <thead>
          <tr>
            <th class="checkbox-column text-center">
              <span class="far fa-eye" />
              <BCheckbox
                v-model="isAllLayersVisible"
                size="is-small"
                :disabled="selectedLayers.length === 0"
                @input="toggleAllLayersVisibility"
              />
            </th>
            <th v-if="!reviewMode" class="checkbox-column text-center">
              <span class="fas fa-pencil-alt" />
            </th>
            <th class="w-full">
              <BField class="max-w-48">
                <BSelect
                  v-model="selectedLayer"
                  :placeholder="$t('select-layer')"
                  size="is-small"
                >
                  <option
                    v-for="layer in unselectedLayers"
                    :key="layer.id"
                    :value="layer"
                  >
                    {{ layerName(layer) }}
                  </option>
                </BSelect>
                <button
                  :disabled="!unselectedLayers.includes(selectedLayer)"
                  class="button is-small"
                  @click="addLayer()"
                >
                  {{ $t('add') }}
                </button>
              </BField>
            </th>
            <th class="checkbox-column text-center" />
          </tr>
        </thead>
        <tbody>
          <template v-for="(layer, idx) in selectedLayers">
            <tr :key="layer.id + '-1'">
              <td class="checkbox-column text-center no-border">
                <BCheckbox
                  :value="layer.visible"
                  size="is-small"
                  @input="toggleLayerVisibility(idx)"
                />
              </td>
              <td
                v-if="!reviewMode"
                class="checkbox-column text-center no-border"
              >
                <BCheckbox
                  :value="layer.drawOn"
                  :disabled="!canDraw(layer)"
                  size="is-small"
                  @input="toggleLayerDrawOn(idx)"
                />
              </td>

              <td class="w-full no-border">
                {{ layerName(layer) }}
              </td>
              <td class="checkbox-column text-center no-border">
                <button
                  v-if="!reviewMode || !layer.isReview"
                  class="button is-small"
                  @click="removeLayer(idx)"
                >
                  <span class="fas fa-times" />
                </button>
              </td>
            </tr>
            <tr :key="layer.id + '-2'">
              <td />
              <td v-if="!reviewMode" />
              <td colspan="1">
                <div class="opacity flex align-center pb-1">
                  <input
                    id="layers-opacity"
                    class="slider is-fullwidth is-small"
                    step="0.05"
                    :value="layer.opacity"
                    min="0"
                    max="1"
                    type="range"
                    @change="(event) => changeOpacity(idx, event)"
                    @input="(event) => changeOpacity(idx, event)"
                  />
                </div>
              </td>
              <td />
            </tr>
          </template>

          <tr v-if="selectedLayers.length === 0">
            <td colspan="4" class="has-text-grey is-italic">
              {{ $t('no-selected-layers') }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { ProjectDefaultLayerCollection } from 'cytomine-client';
import { get } from '@/utils/store-helpers';

import { fullName } from '@/utils/user-utils.js';

export default {
  name: 'LayersPanel',
  props: {
    index: {
      type: String,
      required: true,
    },
    layersToPreload: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      error: false,

      layers: [], // Array<User> (representing user layers)
      indexLayers: [],
      selectedLayer: [],
      isAllLayersVisible: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    project() {
      return this.$store.state.currentProject.project;
    },
    members() {
      return this.$store.state.currentProject.members;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    imageWrapper() {
      return this.viewerWrapper.images[this.index];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    activePanel() {
      return this.imageWrapper.activePanel;
    },
    layersIds() {
      return this.layers.map((layer) => layer.id);
    },
    selectedLayers() {
      // Array<User> (representing user layers)
      return this.imageWrapper.layers.selectedLayers || [];
    },
    selectedLayersIds() {
      return this.selectedLayers.map((layer) => layer.id);
    },
    unselectedLayers() {
      return this.layers.filter(
        (layer) => !this.selectedLayersIds.includes(layer.id)
      );
    },
    nbReviewedAnnotations() {
      return this.indexLayers.reduce(
        (cnt, layer) => cnt + layer.countReviewedAnnotation,
        0
      );
    },
    hasReviewLayer() {
      return this.image.inReview || this.image.reviewed;
    },
    reviewLayer() {
      return {
        id: -1,
        isReview: true,
      };
    },
    reviewMode() {
      return this.imageWrapper.review.reviewMode;
    },
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
  },
  watch: {
    activePanel() {
      this.fetchIndexLayers();
    },
    reviewMode() {
      if (this.reviewMode) {
        if (!this.layers.includes(this.reviewLayer)) {
          this.layers.push(this.reviewLayer);
        }
        this.addLayer(this.reviewLayer);
      } else {
        if (!this.hasReviewLayer) {
          const index = this.selectedLayersIds.findIndex(
            (id) => id === this.reviewLayer.id
          );
          if (index !== -1) {
            this.removeLayer(index);
          }

          this.layers = this.layers.filter((layer) => !layer.isReview);
        }
      }
    },
  },
  async created() {
    try {
      await Promise.all([this.fetchLayers(), this.fetchIndexLayers(true)]);
    } catch (error) {
      console.log(error);
      this.error = true;
      this.$notify({
        type: 'error',
        text: this.$t('notif-error-loading-annotation-layers'),
      });
      return;
    }

    const layersToAdd = this.layersToPreload.map((id) => ({
      id,
      visible: true,
    }));

    if (!this.imageWrapper.layers.selectedLayers) {
      // we do not use computed property selectedLayers because we don't want the replacement by [] if the store array is null
      if (!this.layersToPreload.includes(this.currentUser.id)) {
        layersToAdd.push({
          id: this.currentUser.id,
          visible: true,
        });
      }

      try {
        const defaultLayers = await ProjectDefaultLayerCollection.fetchAll({
          filterKey: 'project',
          filterValue: this.project.id,
        });

        const addedIds = layersToAdd.map((layer) => layer.id);

        defaultLayers.array.forEach(({ user, hideByDefault }) => {
          if (!addedIds.includes(user)) {
            layersToAdd.push({
              id: user,
              visible: !hideByDefault,
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    layersToAdd.map((layer) => this.addLayerById(layer.id, layer.visible));
  },
  mounted() {
    this.$eventBus.$on('addAnnotations', this.addAnnotationsEventHandler);
    this.$eventBus.$on('deleteAnnotations', this.deleteAnnotationsEventHandler);
    this.$eventBus.$on('reloadAnnotations', this.reloadAnnotationsHandler);
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
  },
  beforeDestroy() {
    this.$eventBus.$off('addAnnotations', this.addAnnotationsEventHandler);
    this.$eventBus.$off(
      'deleteAnnotations',
      this.deleteAnnotationsEventHandler
    );
    this.$eventBus.$off('reloadAnnotations', this.reloadAnnotationsHandler);
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  },
  methods: {
    addAnnotationsEventHandler(annots, saved = true) {
      this.annotationEventHandler(annots);
      const updatedProject = this.$store.state.currentProject.project.clone();

      annots.forEach((annot) => {
        if (annot.type === 'UserAnnotation') {
          if (saved) updatedProject.numberOfAnnotations++;
        } else {
          updatedProject.numberOfReviewedAnnotations++;
        }
      });

      this.$store.dispatch('currentProject/updateProject', updatedProject);
    },
    deleteAnnotationsEventHandler(annots) {
      this.annotationEventHandler(annots);

      const updatedProject = this.$store.state.currentProject.project.clone();
      annots.forEach((annot) => {
        if (annot.type === 'UserAnnotation') {
          updatedProject.numberOfAnnotations--;
        } else {
          updatedProject.numberOfReviewedAnnotations--;
        }
      });

      this.$store.dispatch('currentProject/updateProject', updatedProject);
    },
    annotationEventHandler(annots) {
      if (annots.some((a) => a.image === this.image.id)) {
        this.fetchIndexLayers();
      }
    },
    reloadAnnotationsHandler({ idImage } = {}) {
      if (!idImage || idImage === this.image.id) {
        this.fetchIndexLayers();
      }
    },

    layerName(layer) {
      if (layer.isReview) {
        return `${this.$t('review-layer')} (${this.nbReviewedAnnotations})`;
      }

      const name = fullName(layer);

      const indexLayer =
        this.indexLayers.find((index) => index.user === layer.id) || {};
      return `${name} (${indexLayer.countAnnotation || 0})`;
    },

    canDraw(layer) {
      // TODO: if can no longer draw, reset termsToAssociate to []
      const member = this.members.find(
        (user) => user.id == this.currentUser.id
      );
      if (member) {
        if (member.role == 'contributor' && layer.id != this.currentUser.id) {
          return false;
        }
      }
      return (
        !layer.isReview &&
        this.$store.getters['currentProject/canEditLayer'](layer.id)
      );
    },

    addLayerById(id, visible) {
      const layer = this.layers.find((layer) => layer.id === id);
      if (layer) {
        this.addLayer(layer, visible);
      }
    },

    addLayer(layer = this.selectedLayer, visible = true) {
      if (this.selectedLayersIds.includes(layer.id)) {
        return;
      }

      layer.visible = visible;
      layer.drawOn = layer.id === this.currentUser.id && this.canDraw(layer);
      this.$store.dispatch(this.imageModule + 'addLayer', layer);
      this.isAllLayersVisible = this.selectedLayers.every(
        (layer) => layer.visible
      );
      this.selectedLayer = [];
    },

    removeLayer(index) {
      this.$store.dispatch(this.imageModule + 'removeLayer', index);
    },

    toggleLayerVisibility(index) {
      this.$store.dispatch(this.imageModule + 'toggleLayerVisibility', [index]);
      this.isAllLayersVisible = this.selectedLayers.every(
        (layer) => layer.visible
      );
    },
    toggleAllLayersVisibility(isVisible) {
      for (const layer of this.selectedLayers) {
        this.$store.dispatch(this.imageModule + 'toggleLayerVisibility', [
          this.selectedLayers.indexOf(layer),
          isVisible,
        ]);
      }
    },
    toggleLayerDrawOn(index) {
      this.$store.commit(this.imageModule + 'toggleLayerDrawOn', index);
      let layer;
      for (layer of this.selectedLayers) {
        if (this.selectedLayers.indexOf(layer) != index) {
          layer.drawOn = false;
        }
      }
    },

    async fetchLayers() {
      this.layers = (await this.project.fetchUserLayers(this.image.id)).array;
      if (this.hasReviewLayer) {
        this.layers.push(this.reviewLayer);
      }

      // if image instance was changed (e.g. with previous/next image navigation), some of the selected layers
      // may not be relevant for the current image => filter them
      const idLayers = this.layers.map((layer) => layer.id);
      this.$store.commit(this.imageModule + 'filterSelectedLayers', idLayers);
    },

    async fetchIndexLayers(force = false) {
      if (!force && this.activePanel !== 'layers') {
        return;
      }
      this.indexLayers = await this.image.fetchAnnotationsIndex();
    },

    shortkeyHandler(key) {
      if (!this.isActiveImage) {
        // shortkey should only be applied to active map
        return;
      }

      if (key === 'tool-review-toggle') {
        // toggle review layer
        const index = this.selectedLayersIds.findIndex(
          (id) => id === this.reviewLayer.id
        );
        if (index !== -1) {
          this.toggleLayerVisibility(index);
          return;
        }

        if (this.layers.includes(this.reviewLayer)) {
          this.addLayer(this.reviewLayer);
        }
      }
    },
    changeOpacity(index, event) {
      const opacity = Number(event.target.value);
      this.$store.commit(this.imageModule + 'setLayerOpacity', [
        index,
        opacity,
      ]);
    },
  },
};
</script>

<style scoped>
>>> select {
  width: 21em;
}

.table {
  margin-bottom: 1em !important;
  font-size: 0.9em;
}

.table tbody {
  display: block;
  overflow: auto;
  max-height: 10em;
}

.table thead tr {
  display: block;
}

td,
th {
  padding: 0.25em !important;
  vertical-align: middle !important;
}

td .button {
  width: 1.5em;
  height: 1.5em;
  font-size: 0.9em;
  padding: 0;
}

.no-border {
  border: none !important;
}

.checkbox-column {
  min-width: 2.2em;
}

>>> .checkbox .control-label {
  padding: 0 !important;
}

>>> input[type='range'].slider {
  margin: 0;
  padding: 0;
}
</style>
