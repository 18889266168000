<template>
  <ListSlidesModal
    :data="selectedGroupData"
    :is-active.sync="showModal"
    :project-id="project.id"
  />
</template>

<script>
import ListSlidesModal from '../utils/ListSlidesModal.vue';

export default {
  name: 'PieChart',
  components: {
    ListSlidesModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    columnIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    selectedGroupData: [],
    showModal: false,
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    xAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).xAxis[0];
    },
  },
  watch: {
    xAxis() {
      this.initChart();
    },
  },
  created() {
    // configure what's displayed in the chart controls panel
    this.$store.commit('projectVisualizations/setChartConfig', {
      xAxis: true,
      xAxisGrouped: true,
      yAxis: false,
    });
  },
  mounted() {
    this.initChart();
  },
  methods: {
    getGroupingValues() {
      const valuesByDimension = this.data.map((data) => data[this.xAxis]);
      const uniqueValues = valuesByDimension.filter(
        (val, i) => valuesByDimension.indexOf(val) === i
      );
      uniqueValues.sort();
      return uniqueValues;
    },
    getSeriesData() {
      const series = {
        name: this.xAxis,
        data: [],
      };
      const groupValues = this.getGroupingValues();
      const totalDataLength = this.data.length;
      for (const [index, groupVal] of groupValues.entries()) {
        const groupData = this.data.filter((a) => a[this.xAxis] === groupVal);
        if (groupData.length > 0) {
          const size = (groupData.length / totalDataLength) * 100;
          const roundedSize = Math.round(size * 100) / 100;
          series.data.push({
            name: groupVal,
            y: roundedSize,
            custom: {
              count: groupData.length,
            },
          });
        }
      }
      return series;
    },
    initChart() {
      const seriesData = this.getSeriesData();
      // @ts-ignore
      const chartOptions = {
        chart: {
          type: 'pie',
        },
        title: {
          text: this.xAxis,
        },
        tooltip: {
          pointFormat:
            '{series.name}: <b>{point.percentage:.1f}%</b><br />Count: <b>{point.custom.count}</b>',
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%'],
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
          series: {
            cursor: 'pointer',
            size: '75%',
            point: {
              events: {
                click: (event) => {
                  this.selectedGroupData = this.data.filter(
                    (a) => a[this.xAxis] === event.point.name
                  );
                  this.showModal = true;
                },
              },
            },
          },
        },
        series: [seriesData],
      };

      this.$emit('buildChart', {
        chartOptions,
      });
    },
  },
};
</script>

<style scoped></style>
