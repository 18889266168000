const heatmapDefaults = {
  heatmapBlur: 20,
  heatmapRadius: 10,
};

export default {
  state() {
    return {
      zoom: null, // will be initialized to appropriate value (depending on container size) in CytomineImage
      center: [0, 0],
      rotation: 0,
      digitalZoom: true,
      overviewCollapsed: false,
      heatmapMode: false,
      clustering: true,
      ...heatmapDefaults,
    };
  },

  mutations: {
    setCenter(state, center) {
      state.center = center;
    },

    setZoom(state, zoom) {
      state.zoom = zoom;
    },

    setRotation(state, rotation) {
      state.rotation = rotation;
    },

    setDigitalZoom(state, digitalZoom) {
      state.digitalZoom = digitalZoom;
    },

    setOverviewCollapsed(state, value) {
      state.overviewCollapsed = value;
    },

    setHeatmapRadius(state, radius) {
      state.heatmapRadius = radius;
    },

    setHeatmapBlur(state, blur) {
      state.heatmapBlur = blur;
    },

    toggleHeatmap(state, value) {
      state.heatmapMode = value;
    },

    resetHeatmap(state) {
      state.heatmapRadius = heatmapDefaults.heatmapRadius;
      state.heatmapBlur = heatmapDefaults.heatmapBlur;
    },

    toggleClustering(state, value) {
      state.clustering = value;
    },
  },

  actions: {
    async initialize({ commit }, image) {
      commit('setCenter', [image.width / 2, image.height / 2]);
    },
  },
};
