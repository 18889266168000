<template>
  <div
    style="border-top:1px solid lightgray; background-color:#fff;"
    class="absolute bottom-0 w-full"
  >
    <div class="columns mt-0 mx-0">
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
      <div
        v-for="term in displayTerms"
        :key="term.id"
        :class="{ disabled: !terms[termsMapping[term.id]].visible }"
        class="column p-2 text-center"
        style="flex:none; width:10%; border-right:1px dashed lightgray; cursor:pointer; position:relative;"
        @click="toggleTermVisibility(termsMapping[term.id])"
      >
        <b-tooltip
          type="is-light"
          :triggers="['hover']"
          :auto-close="['outside', 'escape']"
          style="position:absolute; top:0px; left:50%; transform:translateX(-50%);"
          append-to-body
        >
          <IdxBtn link style="font-size:11px;">
            <i class="fas fa-chevron-up" />
          </IdxBtn>
          <template #content>
            <div style="min-width:100px; text-align:center;">
              <div>
                {{ term.name }}
              </div>
              <input
                v-if="term.id"
                :disabled="!terms[termsMapping[term.id]].visible"
                :value="terms[termsMapping[term.id]].opacity"
                class="slider m-0 is-small"
                step="0.05"
                min="0"
                max="1"
                type="range"
                aria-label="Opacity"
                @change="(event) => changeOpacity(termsMapping[term.id], event)"
                @input="(event) => changeOpacity(termsMapping[term.id], event)"
              />
            </div>
          </template>
        </b-tooltip>
        <div class="flex justify-center" style="width:24px;">
          <BCheckbox
            :value="terms[termsMapping[term.id]].visible"
            size="is-small"
            style="pointer-events:none;"
          />
        </div>
        <span
          v-if="getHotkeyByTermId(term.id)"
          class="mr-2 absolute top-8 right-0"
          style="font-size:0.8em;background-color:#cecece;padding:2px 6px;border-radius:2px;"
        >
          {{ getHotkeyByTermId(term.id) }}
        </span>
        <div>
          <ColorPreview :color="term.color" />
          <div style="overflow:hidden;">
            {{ term.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="columns" style="border-top:1px solid lightgray;">
      <div class="column is-full text-center" style="height:2em;">
        <IdxBtn
          link
          style="position:relative; bottom:12px; width:100%;"
          @click="$emit('update:active', false)"
        >
          <i class="fas fa-chevron-down" />
        </IdxBtn>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPreview from '@/components/utils/ColorPreview.vue';

export default {
  name: 'OntologyBar',
  components: { ColorPreview },
  props: {
    index: { type: String, required: true },
    active: { type: Boolean, required: true },
  },
  data() {
    return {
      displayTerms: [],
      searchString: '',
      isSelectedTermsUpdated: false,
      selectedTerms: [],
      visibleTerms: [],
      assigningHotkeyToTermId: '',
    };
  },
  computed: {
    /** @returns {object} */
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    /** @returns {string} */
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    /** @returns {object} */
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    /** @returns {object} */
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    /** @returns {boolean} */
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    /** @returns {object} */
    customHotkeys() {
      return this.$store.getters[this.viewerModule + 'getCustomHotkeys'];
    },
    /** @returns {Array} */
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    /** @returns {boolean} */
    noActiveLayer() {
      return !this.layers.find((layer) => layer.drawOn);
    },
    /** @returns {object} */
    terms() {
      return this.imageWrapper.style.terms;
    },
    /** @returns {object} */
    termsMapping() {
      const mapping = {};
      this.terms.forEach((term, idx) => (mapping[term.id] = idx));
      return mapping;
    },
    /** @returns {object[]} */
    additionalNodes() {
      return [
        {
          id: 0,
          name: this.$t('no-term'),
        },
      ];
    },
    displayNoTerm: {
      /** @returns {boolean} */
      get() {
        return this.imageWrapper.style.displayNoTerm;
      },
      /** @param {boolean} value */
      set(value) {
        this.$store.dispatch(this.imageModule + 'setDisplayNoTerm', value);
      },
    },
    noTermOpacity: {
      /** @returns {number} */
      get() {
        return this.imageWrapper.style.noTermOpacity;
      },
      /** @param {number} value */
      set(value) {
        this.$store.commit(
          this.imageModule + 'setNoTermOpacity',
          Number(value)
        );
      },
    },
    isAllTermsChecked: {
      get() {
        /** @returns {boolean} */
        return this.visibleTerms.every(
          (term) => this.terms[this.termsMapping[term.id]].visible
        );
      },
      set(isVisible) {
        for (const term of this.visibleTerms) {
          this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [
            this.termsMapping[term.id],
            isVisible,
          ]);
        }
        this.isSelectedTermsUpdated = true;
      },
    },
  },
  mounted() {
    this.$eventBus.$on('termsUpdated', this.onTermsUpdated);
  },
  beforeDestroy() {
    this.$eventBus.$off('termsUpdated', this.onTermsUpdated);
  },
  created() {
    this.displayTerms = this.getTerms();
  },
  methods: {
    // retrieve the top 10 terms from the ontology
    getTerms() {
      const termsOrder =
        localStorage.getItem('termsOrder_' + this.ontology.id)?.split(',') ||
        [];
      let orderedTerms = [];
      if (termsOrder.length > 0) {
        termsOrder.forEach((termName) => {
          const termIndex = this.ontology.terms.findIndex(
            (term) => term.name === termName
          );
          if (termIndex >= 0) {
            orderedTerms.push(this.ontology.terms[termIndex]);
          }
        });
      } else {
        orderedTerms = [...this.ontology.terms];
      }

      return orderedTerms.splice(0, 10);
    },
    toggleTermVisibility(index) {
      this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [index]);
      this.isSelectedTermsUpdated = true;
    },
    toggleSelectedTermsVisibility(selectedTerms) {
      for (const term of selectedTerms) {
        this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [
          this.termsMapping[term.id],
        ]);
      }
    },
    changeOpacity(index, event) {
      const opacity = Number(event.target.value);
      this.$store.commit(this.imageModule + 'setTermOpacity', {
        indexTerm: index,
        opacity,
      });
    },
    resetOpacities() {
      this.$store.commit(this.imageModule + 'resetTermOpacities');
    },
    isDefaultTerm(termId) {
      return this.defaultTermIds.includes(termId);
    },
    removeHotkey(termId) {
      this.$store.dispatch(this.viewerModule + 'assignCustomHotkey', {
        key: 'term-' + this.getHotkeyByTermId(termId),
        value: null,
      });
    },
    getHotkeyByTermId(termId) {
      if (!this.customHotkeys) {
        return null;
      }
      for (const key of Object.keys(this.customHotkeys)) {
        if (this.customHotkeys[key] === termId.toString()) {
          return key.replace('term-', '');
        }
      }
      return null;
    },
    onTermsUpdated() {
      this.displayTerms = this.getTerms();
    },
  },
};
</script>

<style scoped>
.visibility {
  width: 2.8em;
  height: 2.1em;
}

.disabled {
  background-color: #f2f2f2;
}

>>> .sl-vue-tree-title {
  align-items: center;
}

>>> .checkbox .control-label {
  padding: 0 !important;
}
</style>
