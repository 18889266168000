<template/>

<script>
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.js';

HighchartsMore(Highcharts);

export default {
  name: 'ScatterPlot',
  props: {
    data: {
      type: Array,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    columnIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    xAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).xAxis[0];
    },
    yAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).yAxis[0];
    },
  },
  watch: {
    xAxis() {
      this.initChart();
    },
    yAxis() {
      this.initChart();
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    getSeriesData() {
      const series = {
        name: 'Slide',
        id: 'slide',
        marker: {
          symbol: 'circle',
        },
        showInLegend: false,
        data: [],
      };
      let seriesData = this.data.map((data) => {
        return [
          data[this.xAxis],
          data[this.yAxis],
          data.instanceFilename,
          data.id,
        ];
        // return {
        //   name: data.instanceFilename,
        //   showInLegend: false,
        //   data: [data[this.xAxis], data[this.yAxis]],
        // };
      });
      seriesData = seriesData.filter((data) => data);
      series.data.push(...seriesData);
      return [series];
    },
    initChart() {
      const seriesData = this.getSeriesData();
      // @ts-ignore
      const chartOptions = {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
        },
        title: {
          text: `Slides by ${this.yAxis} and ${this.xAxis}`,
        },
        xAxis: {
          title: {
            text: this.xAxis,
          },
        },
        yAxis: {
          title: {
            text: this.yAxis,
          },
        },
        plotOptions: {
          series: {
            keys: ['x', 'y', 'slideName', 'id'],
            point: {
              events: {
                click: (event) => {
                  const routeData = this.$router.resolve({
                    path: `/project/${this.project.id}/image/${event.point.id}`,
                  });
                  window.open(routeData.href, '_blank');
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat: `{point.slideName} <br/><br/> ${this.xAxis}: <strong>{point.x}</strong> <br/> ${this.yAxis}: <strong>{point.y}</strong>`,
        },
        series: seriesData,
      };

      this.$emit('buildChart', {
        chartOptions,
      });
    },
  },
};
</script>

<style scoped></style>
