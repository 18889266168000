import { ImageInstance, AbstractImage, AnnotationType } from 'cytomine-client';

import colors from './image_modules/colors.js';
import draw from './image_modules/draw.js';
import layers from './image_modules/layers.js';
import properties from './image_modules/properties.js';
import selectedFeatures from './image_modules/selected-features.js';
import style from './image_modules/style.js';
import tracking from './image_modules/tracking.js';
import undoRedo from './image_modules/undo-redo.js';
import view from './image_modules/view.js';
import review from './image_modules/review.js';
import channels from './image_modules/channels.js';
import {
  isCluster,
  createColorStyle,
  createLineStyle,
  createTextStyle,
  getSelectStyles,
  verticesStyle,
  getReviewedStyles,
  getReviewedSelectStyles,
  getRejectedStyles,
  getRejectedSelectStyles,
} from '@/utils/style-utils.js';
import constants from '@/utils/constants.js';

export default {
  namespaced: true,

  state() {
    return {
      imageInstance: null,
      activePanel: null,
    };
  },

  mutations: {
    setImageInstance(state, image) {
      state.imageInstance = image;
    },

    setResolution(state, resolution) {
      state.imageInstance.resolution = resolution;
    },

    togglePanel(state, panel) {
      state.activePanel = state.activePanel === panel ? null : panel;
    },
  },

  actions: {
    async initialize({ commit }, image) {
      const clone = image.clone();
      await fetchImageServers(clone);
      commit('setImageInstance', clone);
    },

    async setImageInstance({ dispatch, rootState }, image) {
      await dispatch('initialize', image);
      const idProject = rootState.currentProject.project.id;
      const idViewer = rootState.currentProject.currentViewer;
      dispatch(`projects/${idProject}/viewers/${idViewer}/changePath`, null, {
        root: true,
      });
    },

    async refreshData({ state, commit }) {
      const image = await fetchImage(state.imageInstance.id);
      commit('setImageInstance', image);
    },
  },

  getters: {
    getAnnotationCounts: (state) => {
      const counts = {
        total: 0,
        loaded: 0,
      };
      if (state.layers?.selectedLayers?.length) {
        for (const layer of state.layers.selectedLayers) {
          counts.loaded += layer.loadedAnnotations ?? 0;
          counts.total += layer.totalAnnotations ?? 0;
        }
      }

      return counts;
    },
    genStyleFunction: (state, getters) => (feature) => {
      const annot = feature.get('annot');
      if (!annot) {
        return;
      }

      // QUESTION: what to do with clusters (returned count does not take into account the selected terms) ?
      // Possible solutions:
      // 1. in backend, for clusters, send array with composition of cluster (x for term 1, y for term 2, z for term1-2)
      // 2. force source refresh every time the list of terms to display is updated
      // 3. add parameter allowing to provide the terms to take into account in kmeans (but only for kmeans)
      if (isCluster(feature)) {
        return [
          state.style.defaultStyle,
          createTextStyle(annot.count.toString()),
        ];
      }

      const annotationLayer = state.layers.selectedLayers.find(
        (a) => a.id === annot.user
      );

      const styles = [];

      const numTerms = annot.term.length;
      const visibleTerms = annot.term.filter(
        (termId) => getters.termsMapping[termId].visible
      );
      const coloredTerms = visibleTerms.filter(
        (termId) => getters.termsMapping[termId].color
      );
      const terms = state.style.terms;
      let pointSize = state.style.pointSize;

      if (
        terms &&
        (numTerms === 1 ||
          visibleTerms.length === 1 ||
          coloredTerms.length === 1)
      ) {
        // annotation has a single term
        const termToUse = coloredTerms.length
          ? coloredTerms[0]
          : visibleTerms[0]; // if there is a colored term, use it, otherwise use the first visible term (term w/ no color)
        const wrappedTerm =
          visibleTerms.length && getters.termsMapping[termToUse];
        if (wrappedTerm) {
          if (feature.getGeometry().getType() === 'LineString') {
            styles.push(
              createLineStyle(
                wrappedTerm.color,
                wrappedTerm.opacity *
                  state.style.globalOpacity *
                  annotationLayer.opacity
              )
            );
          } else {
            if (wrappedTerm.pointSize) {
              pointSize = wrappedTerm.pointSize; // we will use this point size later if the feature is selected, reviewed, or rejected
            }
            styles.push(
              createColorStyle(
                wrappedTerm.color,
                wrappedTerm.strokeColor,
                wrappedTerm.strokeWidth,
                wrappedTerm.opacity *
                  state.style.globalOpacity *
                  annotationLayer.opacity,
                wrappedTerm.pointSize
              )
            );
          }
        } else {
          return; // do not display annot
        }
      } else if (terms && numTerms > 1) {
        // annotation has multiple terms
        const hasTermsToDisplay = visibleTerms.length > 0;
        if (!hasTermsToDisplay) {
          return; // do not display
        }

        // use the highest point size assigned to a term if it exists
        const applicableTerms = terms.filter(
          (term) =>
            term.pointSize && term.pointSize > 1 && annot.term.includes(term.id)
        );
        const customPointSize = Math.max.apply(
          Math,
          applicableTerms.map((a) => a.pointSize)
        );
        if (customPointSize > 1) {
          pointSize = customPointSize; // we will use this point size later if the feature is selected, reviewed, or rejected
        }
        styles.push(
          createColorStyle(
            state.style.multiTermFillColor,
            state.style.multiTermStrokeColor,
            state.style.multiTermStrokeWidth,
            state.style.globalOpacity * annotationLayer.opacity,
            pointSize
          )
        );
      } else {
        // no terms assigned to annot
        if (!state.style.displayNoTerm) {
          return; // do not display annot
        }
        styles.push(
          createColorStyle(
            state.style.defaultFillColor, // fillColor
            state.style.defaultStrokeColor, // strokeColor
            state.style.defaultStrokeWidth, // strokeWidth
            state.style.globalOpacity * annotationLayer.opacity, // opacity
            state.style.defaultPointSize // point size radius
          )
        );
      }

      const isSelected = getters.selectedFeaturesDictById[feature.getId()];
      const isReviewed = annot.type === AnnotationType.REVIEWED;
      const isRejected = state.review.reviewMode && !isReviewed;

      // Styles for selected elements
      if (isSelected) {
        styles.push(
          ...(isReviewed
            ? getReviewedSelectStyles(pointSize)
            : isRejected
            ? getRejectedSelectStyles(pointSize)
            : getSelectStyles(pointSize))
        );
        // if in modify mode, display vertices
        if (state.draw.activeEditTool === 'modify') {
          styles.push(verticesStyle);
        }
      } else if (isReviewed) {
        styles.push(...getReviewedStyles(pointSize));
      } else if (isRejected) {
        styles.push(...getRejectedStyles(pointSize));
      }

      // Properties
      const propValue = state.properties.selectedPropertyValues[annot.id];
      if (propValue) {
        const color = state.properties.selectedPropertyColor;
        let fontSize = '34px';
        if (state.view.zoom <= 3) {
          fontSize = '12px';
        } else if (state.view.zoom <= 6) {
          fontSize = '19px';
        } else if (state.view.zoom <= 8) {
          fontSize = '26px';
        }
        styles.push(createTextStyle(propValue, fontSize, color.fill, null));
      }

      return styles;
    },

    maxZoom: (state) => {
      if (!state.imageInstance) {
        return 0;
      }
      const increment = state.view.digitalZoom
        ? constants.DIGITAL_ZOOM_INCREMENT
        : 0;
      return state.imageInstance.depth + increment;
    },
  },

  modules: {
    colors,
    draw,
    layers,
    properties,
    selectedFeatures,
    style,
    tracking,
    undoRedo,
    view,
    review,
    channels,
  },
};

/**
 * @param idImage
 */
async function fetchImage(idImage) {
  const image = await ImageInstance.fetch(idImage);
  await fetchImageServers(image);
  return image;
}

/**
 * @param image
 */
async function fetchImageServers(image) {
  if (!image.imageServerURLs) {
    image.imageServerURLs = await new AbstractImage({
      id: image.baseImage,
    }).fetchImageServers();
    const imsUrls = image.imageServerURLs;
    const imsUrl = imsUrls[0].substring(imsUrls[0].indexOf('/image'));
    const imsDomains = JSON.parse(constants.IMS_SERVER_LIST);
    image.imageServerURLs = imsDomains.map((imsDomain) => imsDomain + imsUrl);
  }
}
