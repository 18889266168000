import { AnnotationTermCollection, AnnotationType } from 'cytomine-client';
import { fromCircle as polygonFromCircle } from 'ol/geom/Polygon';
import WKT from 'ol/format/WKT';
import { get } from 'lodash';

/** Enum providing the actions that can be performed on annotations */
export const Action = Object.freeze({
  SELECT: 'select',
  CREATE: 'create',
  BULK_CREATE: 'bulk-create',
  UPDATE: 'update',
  DELETE: 'delete',
});

/**
 * Fetch the terms associated to the provided annot, and populate term and userByTerm properties accordingly
 *
 * @param {object} annot The annotation to update
 */
export async function updateTermProperties(annot) {
  const annotTerms = await AnnotationTermCollection.fetchAll({
    filterKey: 'annotation',
    filterValue: annot.id,
  });
  annot.term = [];
  annot.userByTerm = [];
  const mapping = {};
  annotTerms.array.forEach(({ term, user }) => {
    if (!annot.term.includes(term)) {
      mapping[term] = annot.term.length;
      annot.term.push(term);
      annot.userByTerm.push({
        term,
        user: [user],
      });
    } else {
      // this term was already treated => add user to existing userByTerm object related to the term
      annot.userByTerm[mapping[term]].user.push(user);
    }
  });
}

/**
 * Checks whether an annotation belongs to the provided layer and image
 *
 * @param {object} annot The annotation
 * @param {object} layer The layer
 * @param {object} [image] The image
 * @returns {boolean} whether or not the annotation belongs to the provided layer and image
 */
export function annotBelongsToLayer(annot, layer, image = null) {
  if (image && annot.image !== image.id) {
    return false;
  }
  const isReviewed = annot.type === AnnotationType.REVIEWED;
  return layer.isReview ? isReviewed : !isReviewed && annot.user === layer.id;
}

export function transformWktLocation(wktLocation, mirroring, image) {
  if (mirroring === 'horizontal') {
    // matches all numbers (including fractions) that are follow by a whitespace character
    // the string being processed is a WKT string, so this amounts to finding
    // the x-coordinates of the WKT string
    const regex = /[\d\.]+\s/g;
    const result = wktLocation.replaceAll(regex, (match) => {
      const lastChar = match.substring(match.length - 1);
      const mappedCoord =
        image.width - Number(match.substring(0, match.length - 1));
      return String(mappedCoord) + lastChar;
    });
    return result;
  }
  if (mirroring === 'vertical') {
    // matches all numbers (including fractions) that are follow by right parenthesis or a comma symbol
    // the string being processed is a WKT string, so this amounts to finding
    // the y-coordinates of the WKT string
    const regex = /[\d\.]+[\)\,]/g;
    const result = wktLocation.replaceAll(regex, (match) => {
      const lastChar = match.substring(match.length - 1);
      const mappedCoord =
        image.height - Number(match.substring(0, match.length - 1));
      return String(mappedCoord) + lastChar;
    });
    return result;
  } else {
    return wktLocation;
  }
}

/**
 * Takes an OL feature and returns its WKT representation based on the current image
 *
 * @param feature
 */
export function getWktLocation(feature) {
  // transform circle to circular polygon
  const geometry = feature.getGeometry();
  if (geometry.getType() === 'Circle') {
    feature.setGeometry(polygonFromCircle(geometry));
  }
  return new WKT().writeFeature(feature);
}

/**
 * Takes an OL feature and returns its WKT representation based on the unmirrored image (how it's saved in the database)
 *
 * @param feature
 * @param mirroring gets the WKT location based on the unmirrored image (as it's saved in the database)
 * @param image required if mirroring is not false
 */
export function getUnmirroredWktLocation(feature, mirroring, image) {
  // transform circle to circular polygon
  const geometry = feature.getGeometry();
  if (geometry.getType() === 'Circle') {
    feature.setGeometry(polygonFromCircle(geometry));
  }
  const wktString = new WKT().writeFeature(feature);
  return transformWktLocation(wktString, mirroring, image);
}
