<template>
  <div ref="playground" class="annotation-details-playground absolute">
    <VueDraggableResizable
      v-if="selectedFeatures && selectedFeatures.length > 1 && reload"
      v-show="displayAnnotDetails"
      :parent="true"
      :resizable="false"
      :w="width"
      :h="height"
      :x="positionAnnotDetails.x"
      :y="positionAnnotDetails.y"
      class="
        draggable
        flex flex-column
        border-1 border-gray-3
        radius-4
        bg-gray-1
      "
      drag-handle=".drag"
      @dragstop="dragStop"
    >
      <div class="flex align-center border-b-1 border-gray-3 p-1 bg-gray-2">
        <h2 class="flex-grow m-0 size-16">
          {{ $t('selected-annotations') }}
        </h2>
        <IdxBtn small class="drag close ml-1 w-28">
          <i class="fas fa-arrows-alt" />
        </IdxBtn>
        <IdxBtn
          small
          class="close ml-1 w-28"
          @click="displayAnnotDetails = false"
        >
          <i class="fas fa-times" />
        </IdxBtn>
      </div>

      <div class="p-2 overflow-auto h-full">
        <AnnotationsDetails
          :index="index"
          :annotations="selectedFeatures"
          :all-terms="terms"
          :users="allUsers"
          @addTerm="addTerm"
          @updateTerm="updateTerm"
          @deleteTerm="deleteTerm"
          @deletion="handleDeletion"
        />
      </div>
    </VueDraggableResizable>
  </div>
</template>

<script>
import { UserCollection, UserJobCollection } from 'cytomine-client';
import VueDraggableResizable from 'vue-draggable-resizable';
import WKT from 'ol/format/WKT.js';

import { cloneDeep } from 'lodash';
import noteApi from '../../services/noteApi.js';
import { fullName } from '@/utils/user-utils.js';
import { Action, updateTermProperties } from '@/utils/annotation-utils.js';
import AnnotationsDetails from '@/components/annotations/AnnotationsDetails.vue';

export default {
  name: 'AnnotationsDetailsContainer',
  components: {
    VueDraggableResizable,
    AnnotationsDetails,
  },
  props: {
    index: { type: String, default: '' },
    view: { type: Object, default: () => {} },
  },
  data() {
    return {
      width: 320,
      height: 500,
      users: [],
      userJobs: [],
      reload: true,
      format: new WKT(),
      annotations: [],
    };
  },
  computed: {
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    displayAnnotDetails: {
      get() {
        return this.imageWrapper.selectedFeatures.displayAnnotDetails;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setDisplayAnnotDetails', value);
      },
    },
    positionAnnotDetails: {
      get() {
        return this.imageWrapper.selectedFeatures.positionAnnotDetails;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setPositionAnnotDetails', value);
      },
    },
    selectedFeatures() {
      return this.imageWrapper.selectedFeatures.selectedFeatures;
    },
    allUsers() {
      const allUsers = this.users.concat(this.userJobs);
      allUsers.forEach((user) => (user.fullName = fullName(user)));
      return allUsers;
    },
    terms() {
      return this.$store.getters['currentProject/terms'] || [];
    },
    currentUserId() {
      return this.$store.state.currentUser.user.id;
    },
  },
  created() {
    this.fetchUsers();
    this.fetchUserJobs();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$eventBus.$on('updateMapSize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    this.$eventBus.$off('updateMapSize', this.handleResize);
  },
  methods: {
    async fetchUsers() {
      const results = await UserCollection.fetchAll({
        filterKey: 'project',
        filterValue: this.project.id,
      });
      this.users = results.array;
    },
    async fetchUserJobs() {
      this.userJobs = (
        await UserJobCollection.fetchAll({
          filterKey: 'project',
          filterValue: this.image.project,
        })
      ).array;
    },

    // Creates a new term that previously did not exist
    addTerm(term) {
      this.$store.dispatch(this.viewerModule + 'addTerm', term);
    },

    // Adds a term to selected features
    updateTerm(termId, annotIds) {
      const newFeatures = cloneDeep(this.selectedFeatures);

      newFeatures.forEach((feature) => {
        const annot = feature.properties.annot;
        if (!annot.term.includes(termId) && annotIds.includes(annot.id)) {
          annot.term.push(termId);
          annot.userByTerm.push({
            term: termId,
            user: [this.currentUserId],
          });
        }
      });

      this.$store.commit(this.imageModule + 'setSelectedFeatures', newFeatures);

      this.$eventBus.$emit(
        'editAnnotations',
        newFeatures.map((a) => a.properties.annot),
        false
      );
    },

    deleteTerm(termId, annotIds) {
      const newFeatures = cloneDeep(this.selectedFeatures);

      newFeatures.forEach((feature) => {
        const annot = feature.properties.annot;
        if (annot.term.includes(termId) && annotIds.includes(annot.id)) {
          const termIndex = annot.term.indexOf(termId);
          if (termIndex >= 0) {
            annot.term.splice(termIndex, 1);
          }
          const userIndex = annot.userByTerm.findIndex(
            (a) => a.term === termId
          );
          if (userIndex >= 0) {
            annot.userByTerm.splice(userIndex, 1);
          }
        }
      });

      this.$store.commit(this.imageModule + 'setSelectedFeatures', newFeatures);

      this.$eventBus.$emit(
        'editAnnotations',
        newFeatures.map((a) => a.properties.annot),
        false
      );
    },

    updateProperties() {
      this.$store.dispatch(this.imageModule + 'refreshProperties', this.index);
    },

    handleDeletion(annotIds) {
      const selectedAnnots = this.selectedFeatures
        .filter((a) => annotIds.includes(a.id))
        .map((a) => a.properties.annot);

      this.$store.commit(this.imageModule + 'addAction', {
        annots: selectedAnnots,
        type: Action.DELETE,
      });

      this.$eventBus.$emit('deleteAnnotations', selectedAnnots);
    },

    dragStop(x, y) {
      this.positionAnnotDetails = {
        x,
        y,
      };
    },

    async handleResize() {
      await this.$nextTick(); // wait for update of clientWidth and clientHeight to their new values

      if (this.$refs.playground) {
        const maxX = Math.max(
          this.$refs.playground.clientWidth - this.width,
          0
        );
        const maxY = Math.max(
          this.$refs.playground.clientHeight - this.height,
          0
        );
        const x = Math.min(this.positionAnnotDetails.x, maxX);
        const y = Math.min(this.positionAnnotDetails.y, maxY);
        this.positionAnnotDetails = {
          x,
          y,
        };

        // HACK to force the component to recreate and take into account new (x,y) ; should no longer be
        // necessary with version 2 of vue-draggable-resizable
        this.reload = false;
        this.$nextTick(() => (this.reload = true));
      }
    },
  },
};
</script>

<style scoped>
.annotation-details-playground {
  inset: 3.5rem 4.5rem 2em 3.5rem;
  pointer-events: none; /* to allow selection of elements below it */
}

.draggable {
  pointer-events: auto;
}
</style>

<style>
.dragging .button.drag {
  background-color: #6899d0;
  color: #fff;
}

.annotation-details-playground .draggable {
  z-index: 15 !important;
}
</style>
